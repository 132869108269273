'use client';
import { useAppState } from '@/components/providers/state-provider';

export const useSetLinksTargetBlank = () => {
  const {
    state: { isBrowser },
  } = useAppState();

  return (html: string) => {
    if (!isBrowser) {
      return html;
    }

    const div = document.createElement('div');
    div.innerHTML = html;
    const elems = [
      ...div.querySelectorAll('a:not([target="_blank"])'),
    ] as HTMLAnchorElement[];
    if (!elems || !elems.length) {
      return html;
    }
    for (const elem of elems) {
      elem.setAttribute('target', '_blank');
      elem.setAttribute('rel', 'noopener noreferrer nofollow');
    }

    return div.innerHTML;
  };
};

// Helper function to generate word variations
const generateWordVariations = (word: string): string[] => {
  const prefixes = [
    'un',
    're',
    'in',
    'im',
    'dis',
    'en',
    'non',
    'over',
    'mis',
    'sub',
    'pre',
    'inter',
    'fore',
    'de',
    'trans',
    'super',
    'semi',
    'anti',
    'mid',
    'under',
  ];

  const suffixes = [
    'able',
    'ible',
    'al',
    'ial',
    'ed',
    'en',
    'er',
    'est',
    'ful',
    'ic',
    'ing',
    'ion',
    'tion',
    'ation',
    'ition',
    'ity',
    'ty',
    'ive',
    'ative',
    'itive',
    'less',
    'ly',
    'ment',
    'ness',
    'ous',
    'eous',
    'ious',
    'es',
    's',
    'y',
  ];

  const stripSuffix = (word: string): string[] => {
    const stripped = new Set<string>();
    suffixes.forEach((suffix) => {
      if (word.endsWith(suffix)) {
        const root = word.slice(0, -suffix.length);
        if (root.length === 1) {
          return;
        }
        stripped.add(root);
      }
    });
    stripped.add(word); // Add the word itself if no suffix is stripped
    return Array.from(stripped);
  };

  const applyPrefixes = (word: string, prefixes: string[]): string[] => {
    return prefixes.map((prefix) => {
      if (prefix === 'un' || prefix === 'dis' || prefix === 'non') {
        return prefix + word;
      }
      return prefix + (word.charAt(0).match(/[aeiou]/) ? '-' : '') + word;
    });
  };

  const applySuffixes = (word: string, suffixes: string[]): string[] => {
    return suffixes.flatMap((suffix) => {
      if (suffix === 'ed' || suffix === 'ing') {
        if (word.endsWith('e')) {
          return [word.slice(0, -1) + suffix, word.slice(0, -1) + suffix + 's'];
        } else if (word.endsWith('y')) {
          return [
            word.slice(0, -1) + 'i' + suffix,
            word.slice(0, -1) + 'i' + suffix + 's',
          ];
        }
      }
      return [word + suffix, word + suffix + 's'];
    });
  };

  const applyPlurals = (word: string): string[] => {
    if (word.endsWith('y')) {
      return [word.slice(0, -1) + 'ies', word + 's'];
    } else if (word.endsWith('s') || word.endsWith('x')) {
      return [word + 'es'];
    } else {
      return [word + 's'];
    }
  };

  const roots = stripSuffix(word);
  const variations = new Set<string>();
  variations.add(word);

  roots.forEach((root) => {
    const prefixedWords = applyPrefixes(root, prefixes);
    const suffixedWords = applySuffixes(root, suffixes);
    const pluralWords = applyPlurals(root);
    const combinedWords = prefixedWords.flatMap((prefixedWord) =>
      applySuffixes(prefixedWord, suffixes),
    );

    variations.add(root);
    prefixedWords.forEach((variation) => variations.add(variation));
    suffixedWords.forEach((variation) => variations.add(variation));
    pluralWords.forEach((variation) => variations.add(variation));
    combinedWords.forEach((variation) => variations.add(variation));
  });

  return Array.from(variations);
};

// Helper function to get keyword set
const getKeywordSet = (searchString: string): Set<string> => {
  const keywords = searchString
    .replace(/[^a-z\- ]/gi, '')
    .split(/\s+/)
    .flatMap((term) => [...generateWordVariations(term), term])
    .map((keyword) => keyword.toLowerCase());
  return new Set(keywords);
};

const highlightClass =
  'bg-[var(--tw-shadow-color)] -z-10 shadow-[-3px_0_0_0_var(--tw-shadow-color),3px_0_0_0_var(--tw-shadow-color),0_-1px_0_0_var(--tw-shadow-color),0_1px_0_0_var(--tw-shadow-color),-3px_-1px_0_0_var(--tw-shadow-color),3px_-1px_0_0_var(--tw-shadow-color),-3px_1px_0_0_var(--tw-shadow-color),3px_1px_0_0_var(--tw-shadow-color)]';

export const useHighlightText = () => {
  const {
    state: { isBrowser },
  } = useAppState();

  // Function to highlight keywords
  const highlightText = (
    html: string,
    searchString: string,
    tagName = 'span',
    color = 'sermons-text-highlight',
  ): string => {
    if (!isBrowser) {
      return html;
    }

    const span = document.createElement('span');
    span.innerHTML = html;
    span.style.setProperty('--tw-shadow-color', `var(--tw-${color})`);

    const keywordSet = getKeywordSet(searchString);

    const innerHighlight = (node: Node): void => {
      if (node.nodeType === Node.TEXT_NODE) {
        const textContent = node.textContent || '';
        const regex = new RegExp(
          `\\b(${Array.from(keywordSet).join('|')})\\b`,
          'gi',
        );
        const parts = textContent.split(regex);
        const newContent = document.createDocumentFragment();

        parts.forEach((part) => {
          if (keywordSet.has(part.toLowerCase())) {
            const highlighted = document.createElement(tagName);
            highlighted.className = highlightClass;
            highlighted.textContent = part;
            newContent.appendChild(highlighted);
          } else {
            newContent.appendChild(document.createTextNode(part));
          }
        });

        if (node.parentNode) {
          node.parentNode.replaceChild(newContent, node);
        }
      } else {
        node.childNodes.forEach((child) => innerHighlight(child));
      }
    };

    innerHighlight(span);

    return span.outerHTML;
  };

  return highlightText;
};

export const useCountOccurrences = () => {
  const {
    state: { isBrowser },
  } = useAppState();

  // Function to count occurrences
  const countOccurrences = (html: string, searchString: string): number => {
    if (!isBrowser) {
      return 0;
    }

    const div = document.createElement('div');
    div.innerHTML = html;

    const keywordSet = getKeywordSet(searchString);
    let count = 0;

    const innerCount = (node: Node): void => {
      if (node.nodeType === Node.TEXT_NODE) {
        const textContent = node.textContent || '';
        const regex = new RegExp(
          `\\b(${Array.from(keywordSet).join('|')})\\b`,
          'gi',
        );
        const matches = textContent.match(regex);
        if (matches) {
          count += matches.length;
        }
      } else {
        node.childNodes.forEach((child) => innerCount(child));
      }
    };

    innerCount(div);

    return count;
  };

  return countOccurrences;
};
