'use client';

import Loading from '@/app/(dynamic)/loading';
import { fetchTextAndTools, hasContent } from '@/lib/helpers';
import {
  Content,
  NewDictionaryWordsPericope,
  NewWeek,
  StudyTools,
  StudyToolsWordsCompact,
} from '@/types/sermons';
import classNames from 'classnames';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import {
  BsCaretLeft,
  BsCaretLeftFill,
  BsCaretRight,
  BsCaretRightFill,
  BsPencil,
  BsPlusCircleFill,
  BsTrash,
  BsXCircle,
} from 'react-icons/bs';
import ContentDeleteDialog from '../admin/content/content-delete-dialog';
import ContentEditDialog from '../admin/content/content-edit-dialog';
import Accordion from '../molecules/accordion';
import Button from '../molecules/button';
import LoggedOutMessage from '../molecules/logged-out-message';
import { useAppState } from '../providers/state-provider';
import CollapsiblePericope from './collapsible-pericope';

export default function Tools({
  studyTools,
  sermon: parentSermon,
  week,
}: {
  studyTools: StudyTools;
  sermon?: Content;
  week?: NewWeek;
}) {
  const router = useRouter();
  const {
    state: { user, token, isLoading, searchParams, isBrowser },
  } = useAppState();

  const pericopeCode = searchParams.get('pericopeCode');

  const pericope = parentSermon?.pericopes?.filter(
    (p) => !pericopeCode || p.number === pericopeCode,
  )?.[0];

  const [selectedStudyToolsWordsCompact, setSelectedStudyToolsWordsCompact] =
    useState<StudyToolsWordsCompact>();
  const [selectedNewDictionaryWord, setSelectedNewDictionaryWord] =
    useState<NewDictionaryWordsPericope>();

  const hasSubscription = !!user?.hasIllustrations;

  const [editingContent, setEditingContent] = useState(false);
  const [deletingContent, setDeletingContent] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [sermon, setSermon] = useState<Content>();
  const [isCreate, setIsCreate] = useState(false);
  const [contentType, setContentType] = useState<string>();

  const [handbook, setHandbook] = useState<Content | undefined>(
    studyTools.handbook,
  );
  const [ovc, setOvc] = useState<Content | undefined>(studyTools.ovc);
  const [exhaustive, setExhaustive] = useState<Content | undefined>(
    studyTools.commentary,
  );

  const updateSermon = (newSermon: Content) => {
    setSermon(newSermon);

    if (studyTools.handbook?.id === newSermon.id) {
      setHandbook(newSermon);
    } else if (studyTools.ovc?.id === newSermon.id) {
      setOvc(newSermon);
    } else if (studyTools.commentary?.id === newSermon.id) {
      setExhaustive(newSermon);
    }
  };

  const [dynamicStudyTools, setDynamicStudyTools] = useState(studyTools);

  const weekPericopes = week?.bibleCode
    ?.split(' or ')
    .map((str) => str.split(','));
  const codes = weekPericopes?.find((arr) =>
    arr.some((code) => code === dynamicStudyTools?.bible.number),
  );
  const [codeIndex, setCodeIndex] = useState(
    (codes || []).indexOf(dynamicStudyTools?.bible.number),
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (codes?.[codeIndex] && token) {
      const code = codes[codeIndex];
      if (dynamicStudyTools?.bible.number !== code) {
        setLoading(true);
        (async () => {
          const result = await fetchTextAndTools(code, token);
          if (!('statusCode' in result)) {
            setDynamicStudyTools(result.studyTools);
            setLoading(false);
          }
        })().catch(console.error);
      }
    }
  }, [codeIndex, token]);

  const prevPericope = codeIndex > 0 ? codes?.[codeIndex - 1] : undefined;
  const nextPericope = codeIndex >= 0 ? codes?.[codeIndex + 1] : undefined;

  return (
    <>
      {week && (
        <>
          <div className='p-4 text-center font-bold'>
            {prevPericope && dynamicStudyTools && (
              <BsCaretLeftFill
                className='relative top-[-2px] mr-1 inline-block cursor-pointer text-sermons-dark'
                onClick={() => setCodeIndex(codeIndex - 1)}
              />
            )}
            {!prevPericope && dynamicStudyTools && (
              <BsCaretLeft className='relative top-[-2px] mr-1 inline-block cursor-not-allowed text-sermons-dark' />
            )}
            <span>Sermon Prep</span>
            {nextPericope && dynamicStudyTools && (
              <BsCaretRightFill
                className='relative top-[-2px] ml-1 inline-block cursor-pointer text-sermons-dark'
                onClick={() => setCodeIndex(codeIndex + 1)}
              />
            )}
            {!nextPericope && dynamicStudyTools && (
              <BsCaretRight className='relative top-[-2px] ml-1 inline-block cursor-not-allowed text-sermons-dark' />
            )}
          </div>
          <div className='-mt-8 p-4 text-center font-bold'>
            {week.serviceNameFull}
            <br />
            {week.citationL}
          </div>
        </>
      )}
      {loading && <Loading />}
      {!loading && (
        <>
          {handbook && (
            <section>
              <Accordion
                header={
                  <span className='font-bold'>{`Overview and Insights${
                    handbook.contentTitle ? ` · ${handbook.contentTitle}` : ''
                  }`}</span>
                }
                icon={BsPlusCircleFill}
                iconRotation={45}
                isOpen={true}
                isRounded={false}
              >
                <div className='float-right p-4 pr-0 pt-0'>
                  {user && user.isAdmin && (
                    <>
                      <Button
                        className='mr-2 inline-flex size-10 items-center justify-center text-3xl'
                        onClick={(e) => {
                          e.preventDefault();
                          setEditingContent(true);
                          setSermon(handbook);
                          setIsCreate(false);
                        }}
                      >
                        <BsPencil />
                      </Button>
                      <Button
                        className='inline-flex size-10 items-center justify-center text-3xl'
                        onClick={(e) => {
                          e.preventDefault();
                          setDeletingContent(true);
                          setSermon(handbook);
                        }}
                      >
                        <BsTrash />
                      </Button>
                    </>
                  )}
                </div>
                <div
                  className='sermon-content'
                  dangerouslySetInnerHTML={{
                    __html:
                      !isLoading && user && hasContent(user, handbook)
                        ? handbook.content
                        : handbook.truncatedContent || '',
                  }}
                ></div>
                {(!user || !hasSubscription || user.isExpired) &&
                  isBrowser &&
                  !isLoading && <LoggedOutMessage />}
                <div className='mt-4 flex items-center justify-end'>
                  <span>
                    <span className='underline'>
                      {handbook.publicationTitle}
                    </span>
                    {handbook.authors && (
                      <span>
                        {' '}
                        by{' '}
                        {handbook.authors.map((author, index) => (
                          <span key={author.id}>
                            {index > 0 && <span>,</span>} {author.firstname}{' '}
                            {author.lastname}
                          </span>
                        ))}
                        , {handbook.publisher}, {handbook.yearPublished}
                      </span>
                    )}
                  </span>
                </div>
              </Accordion>
            </section>
          )}
          {!handbook && user?.isAdmin && (
            <section>
              <Accordion
                header={
                  <span className='font-bold'>Overview and Insights</span>
                }
                icon={BsPlusCircleFill}
                iconRotation={45}
                isOpen={true}
                isRounded={false}
              >
                <Button
                  className='mr-2 inline-flex size-10 items-center justify-center text-3xl'
                  onClick={(e) => {
                    e.preventDefault();
                    setEditingContent(true);
                    setSermon({
                      displayedScripture: [],
                    } as unknown as Content);
                    setIsCreate(true);
                    setContentType('35');
                  }}
                >
                  <BsPencil />
                </Button>
              </Accordion>
            </section>
          )}
          {(pericope?.verses || studyTools.scripture) && (
            <CollapsiblePericope
              pericope={{
                ...(pericope || studyTools.bible),
                verses: pericope?.verses || studyTools.scripture || [],
              }}
              open={true}
            />
          )}
          <section>
            <Accordion
              header={
                <span className='font-bold'>{`Commentary${
                  ovc?.contentTitle ? ` · ${ovc.contentTitle}` : ''
                }`}</span>
              }
              icon={BsPlusCircleFill}
              iconRotation={45}
              isOpen={true}
              isRounded={false}
              tabHeadings={[
                (user?.isAdmin || ovc) && 'Concise',
                (user?.isAdmin || exhaustive) && 'Exhaustive',
              ]}
              tabs={[
                ovc ? (
                  <>
                    <div className='float-right p-4 pr-0 pt-0'>
                      {user && user.isAdmin && (
                        <>
                          <Button
                            className='mr-2 inline-flex size-10 items-center justify-center text-3xl'
                            onClick={(e) => {
                              e.preventDefault();
                              setEditingContent(true);
                              setSermon(ovc);
                              setIsCreate(false);
                            }}
                          >
                            <BsPencil />
                          </Button>
                          <Button
                            className='inline-flex size-10 items-center justify-center text-3xl'
                            onClick={(e) => {
                              e.preventDefault();
                              setDeletingContent(true);
                              setSermon(ovc);
                            }}
                          >
                            <BsTrash />
                          </Button>
                        </>
                      )}
                    </div>
                    <div
                      className='sermon-content'
                      dangerouslySetInnerHTML={{
                        __html:
                          (!isLoading && user && hasContent(user, ovc)) ||
                          !isBrowser
                            ? ovc.content
                            : ovc.truncatedContent || '',
                      }}
                    ></div>
                    {(!user || !hasSubscription || user.isExpired) &&
                      isBrowser &&
                      !isLoading && <LoggedOutMessage />}
                    <div className='mt-4 flex items-center justify-end'>
                      <span>
                        <span className='underline'>
                          {ovc.publicationTitle}
                        </span>
                        {ovc.authors && (
                          <span>
                            {' '}
                            by{' '}
                            {ovc.authors.map((author, index) => (
                              <span key={author.id}>
                                {index > 0 && <span>,</span>} {author.firstname}{' '}
                                {author.lastname}
                              </span>
                            ))}
                            , {ovc.publisher}, {ovc.yearPublished}
                          </span>
                        )}
                      </span>
                    </div>
                  </>
                ) : (
                  user?.isAdmin && (
                    <div className='float-right p-4 pr-0 pt-0'>
                      <Button
                        className='mr-2 inline-flex size-10 items-center justify-center text-3xl'
                        onClick={(e) => {
                          e.preventDefault();
                          setEditingContent(true);
                          setSermon({
                            displayedScripture: [],
                          } as unknown as Content);
                          setIsCreate(true);
                          setContentType('36');
                        }}
                      >
                        <BsPencil />
                      </Button>
                    </div>
                  )
                ),
                exhaustive ? (
                  <>
                    <div className='float-right p-4 pr-0 pt-0'>
                      {user && user.isAdmin && (
                        <>
                          <Button
                            className='mr-2 inline-flex size-10 items-center justify-center text-3xl'
                            onClick={(e) => {
                              e.preventDefault();
                              setEditingContent(true);
                              setSermon(exhaustive);
                              setIsCreate(true);
                            }}
                          >
                            <BsPencil />
                          </Button>
                          <Button
                            className='inline-flex size-10 items-center justify-center text-3xl'
                            onClick={(e) => {
                              e.preventDefault();
                              setDeletingContent(true);
                              setSermon(exhaustive);
                            }}
                          >
                            <BsTrash />
                          </Button>
                        </>
                      )}
                    </div>
                    <div
                      className='sermon-content'
                      dangerouslySetInnerHTML={{
                        __html:
                          (!isLoading &&
                            user &&
                            hasContent(user, exhaustive)) ||
                          !isBrowser
                            ? exhaustive.content
                            : exhaustive.truncatedContent || '',
                      }}
                    ></div>
                    {(!user || !hasSubscription || user.isExpired) &&
                      isBrowser &&
                      !isLoading && <LoggedOutMessage />}
                    <div className='mt-4 flex items-center justify-end'>
                      <span>
                        <span className='underline'>
                          {exhaustive?.publicationTitle}
                        </span>
                        {exhaustive?.authors && (
                          <span>
                            {' '}
                            by{' '}
                            {exhaustive?.authors.map((author, index) => (
                              <span key={author.id}>
                                {index > 0 && <span>,</span>} {author.firstname}{' '}
                                {author.lastname}
                              </span>
                            ))}
                            , {exhaustive?.publisher},{' '}
                            {exhaustive?.yearPublished}
                          </span>
                        )}
                      </span>
                    </div>
                  </>
                ) : (
                  user?.isAdmin && (
                    <div className='float-right p-4 pr-0 pt-0'>
                      <Button
                        className='mr-2 inline-flex size-10 items-center justify-center text-3xl'
                        onClick={(e) => {
                          e.preventDefault();
                          setEditingContent(true);
                          setSermon({
                            displayedScripture: [],
                          } as unknown as Content);
                          setIsCreate(true);
                          setContentType('34');
                        }}
                      >
                        <BsPencil />
                      </Button>
                    </div>
                  )
                ),
              ]}
            />
          </section>
          <section>
            <Accordion
              header={<span className='font-bold'>Dictionary</span>}
              icon={BsPlusCircleFill}
              iconRotation={45}
              isOpen={true}
              isRounded={false}
              tabHeadings={['Concise', 'Exhaustive']}
              tabs={[
                <div key={'compact'}>
                  {studyTools.directMatchesCompact.length > 0 && (
                    <h3>Direct Matches</h3>
                  )}
                  {studyTools.directMatchesCompact.map(
                    (studyToolsWordsCompact) => (
                      <div key={studyToolsWordsCompact.id} className='m-4'>
                        <Accordion
                          header={studyToolsWordsCompact.word.word}
                          isOpen={
                            selectedStudyToolsWordsCompact ===
                            studyToolsWordsCompact
                          }
                          onToggle={(isOpen) => {
                            if (isOpen) {
                              setSelectedStudyToolsWordsCompact(
                                studyToolsWordsCompact,
                              );
                            }
                          }}
                        >
                          <div
                            className='sermon-content'
                            dangerouslySetInnerHTML={{
                              __html:
                                studyToolsWordsCompact.word.definition
                                  .definition,
                            }}
                          ></div>
                          {(!user || !hasSubscription || user.isExpired) &&
                            isBrowser &&
                            !isLoading && <LoggedOutMessage />}
                        </Accordion>
                      </div>
                    ),
                  )}
                  {studyTools.secondaryMatchesCompact.length > 0 && (
                    <h3>Secondary Matches</h3>
                  )}
                  {studyTools.secondaryMatchesCompact.map(
                    (studyToolsWordsCompact) => (
                      <div key={studyToolsWordsCompact.id} className='m-4'>
                        <Accordion
                          header={studyToolsWordsCompact.word.word}
                          isOpen={
                            selectedStudyToolsWordsCompact ===
                            studyToolsWordsCompact
                          }
                          onToggle={(isOpen) => {
                            if (isOpen) {
                              setSelectedStudyToolsWordsCompact(
                                studyToolsWordsCompact,
                              );
                            }
                          }}
                        >
                          <div
                            className='sermon-content'
                            dangerouslySetInnerHTML={{
                              __html:
                                studyToolsWordsCompact.word.definition
                                  .definition,
                            }}
                          ></div>
                          {(!user || !hasSubscription || user.isExpired) &&
                            isBrowser &&
                            !isLoading && <LoggedOutMessage />}
                        </Accordion>
                      </div>
                    ),
                  )}
                </div>,
                <div key={'exhaustive'}>
                  {studyTools.directMatchesExhaustive.length > 0 && (
                    <h3>Direct Matches</h3>
                  )}
                  {studyTools.directMatchesExhaustive.map((dwP) => (
                    <div key={dwP.id} className='m-4'>
                      <Accordion
                        header={dwP.word.word}
                        isOpen={selectedNewDictionaryWord === dwP}
                        onToggle={(isOpen) => {
                          if (isOpen) {
                            setSelectedNewDictionaryWord(dwP);
                          }
                        }}
                      >
                        <div
                          className='sermon-content'
                          dangerouslySetInnerHTML={{
                            __html: dwP.word.definition,
                          }}
                        ></div>
                        {(!user || !hasSubscription || user.isExpired) &&
                          isBrowser &&
                          !isLoading && <LoggedOutMessage />}
                      </Accordion>
                    </div>
                  ))}
                  {studyTools.secondaryMatchesExhaustive.length > 0 && (
                    <h3>Secondary Matches</h3>
                  )}
                  <div className='text-sm italic'>
                    The following suggestions occured because{' '}
                    {studyTools.bible.fullReference} is mentioned in the
                    definition.
                  </div>
                  {studyTools.secondaryMatchesExhaustive.map((dwP) => (
                    <div key={dwP.id} className='m-4'>
                      <Accordion
                        header={dwP.word.word}
                        isOpen={selectedNewDictionaryWord === dwP}
                        onToggle={(isOpen) => {
                          if (isOpen) {
                            setSelectedNewDictionaryWord(dwP);
                          }
                        }}
                      >
                        <div
                          className='sermon-content'
                          dangerouslySetInnerHTML={{
                            __html: dwP.word.definition,
                          }}
                        ></div>
                        {(!user || !hasSubscription || user.isExpired) &&
                          isBrowser &&
                          !isLoading && <LoggedOutMessage />}
                      </Accordion>
                    </div>
                  ))}
                </div>,
              ]}
            />
          </section>
        </>
      )}
      {sermon && (
        <ContentEditDialog
          sermon={sermon}
          isOpen={editingContent}
          setOpen={setEditingContent}
          setError={setError}
          setSuccess={setSuccess}
          onSave={(newSermon) => {
            updateSermon(newSermon);
          }}
          isCreate={isCreate}
          contentType={contentType}
          scripture={{
            code: studyTools.bible.number,
            reference: studyTools.bible.fullReference,
          }}
          studyToolsId={studyTools.id}
        />
      )}
      {sermon && (
        <ContentDeleteDialog
          sermon={sermon}
          isOpen={deletingContent}
          setOpen={setDeletingContent}
          onDelete={() => router.refresh()}
        />
      )}
      <section
        className={classNames('fixed bottom-0 left-0 z-50 w-full text-white', {
          hidden: !error && !success,
        })}
      >
        {error && (
          <div className='m-4 mx-auto flex items-center justify-between rounded bg-red-400 p-4 lg:max-w-6xl'>
            <div className='whitespace-pre-wrap'>{error}</div>
            <div className='flex w-4 items-center text-right text-2xl'>
              <button onClick={() => setError('')}>
                <BsXCircle />
              </button>
            </div>
          </div>
        )}
        {success && (
          <div className='m-4 mx-auto flex items-center justify-between rounded bg-green-400 p-4 lg:max-w-6xl'>
            <div className='whitespace-pre-wrap'>{success}</div>
            <div className='flex w-4 items-center text-right text-2xl'>
              <button onClick={() => setSuccess('')}>
                <BsXCircle />
              </button>
            </div>
          </div>
        )}
      </section>
    </>
  );
}
