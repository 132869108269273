'use client';

import Button from '@/components/molecules/button';
import MyEditor from '@/components/molecules/editor';
import Select from '@/components/molecules/select';
import TextInput from '@/components/molecules/text-input';
import { useAppState } from '@/components/providers/state-provider';
import { fetchContent, isError } from '@/lib/helpers';
import { convertToHtml } from '@/lib/server-actions';
import {
  Content,
  CreateResponse,
  ErrorResponse,
  MY_SERMONS_TOC_TYPE,
  MY_SERMONS_TOC_TYPES,
  PericopeSuggestion,
  PericopeWithVerses,
  TbTocType,
} from '@/types/sermons';
import classNames from 'classnames';
import { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { BsPlusLg, BsTrash } from 'react-icons/bs';
import slugify from 'slugify';

export default function ContentEditor({
  sermon,
  cancelText,
  isCreate,
  setSuccess,
  setError,
  onFocus,
  onSave,
  onCancel,
  onCreate,
  className,
  contentType: defaultContentType,
  scripture,
  studyToolsId,
  clearOnFocus,
}: {
  sermon?: Partial<Content>;
  cancelText?: string;
  isCreate?: boolean;
  setSuccess: (success: string) => void;
  setError: (error: string) => void;
  onFocus?: () => void;
  onSave?: (sermon: Content) => void;
  onCancel?: (sermon: Content) => void;
  onCreate?: () => void;
  className?: string;
  contentType?: string;
  scripture?: PericopeSuggestion;
  studyToolsId?: number;
  clearOnFocus?: boolean;
}) {
  const {
    state: { mySermons, token, user, contentTypes, mySermonsContentTypes },
    dispatch,
  } = useAppState();
  const { isMySermons } = mySermons;

  const saveButtonRef = useRef<HTMLButtonElement>(null);

  const [id, setId] = useState('');
  const [contentTitle, setContentTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [contentType, setContentType] = useState('');
  const [content, setContent] = useState('');
  const [publicationTitle, setPublicationTitle] = useState('');
  const [publisher, setPublisher] = useState('');
  const [yearPublished, setYearPublished] = useState('');
  const [hidden, setHidden] = useState(false);
  const [priority, setPriority] = useState<number | null>(null);
  const [displayedScripture, setDisplayedScripture] = useState<string | null>(
    null,
  );
  const [scriptureReferences, setScriptureReferences] = useState<
    PericopeSuggestion[]
  >([
    {
      code: scripture ? scripture.code : '',
      reference: scripture ? scripture.reference : '',
    },
  ]);
  const [isDraft, setIsDraft] = useState(true);

  const reset = () => {
    setIsDraft(sermon?.isDraft || false);
    setId(sermon?.id || '');
    setContentTitle(sermon?.contentTitle || '');
    setAuthor(
      sermon?.authors && sermon?.authors.length
        ? `${sermon?.authors[0].firstname} ${sermon?.authors[0].lastname}`.trim()
        : '',
    );
    setContentType(sermon?.contentType || '');
    setContent(sermon?.content || '');
    setPublicationTitle(sermon?.publicationTitle || '');
    setPublisher(sermon?.publisher || '');
    setYearPublished(sermon?.yearPublished || '');
    setHidden(sermon?.hidden || false);
    setPriority(sermon?.extraData?.priority || null);
    setDisplayedScripture(
      sermon?.displayedScripture?.[0]?.displayedScripture || null,
    );
    setScriptureReferences(
      sermon?.pericopes && sermon?.pericopes.length
        ? sermon?.pericopes?.map(
            (pericope) =>
              ({
                code: pericope.number,
                reference: pericope.fullReference,
              }) as PericopeSuggestion,
          )
        : [
            {
              code: scripture ? scripture.code : '',
              reference: scripture ? scripture.reference : '',
            },
          ],
    );
    dispatch({
      type: 'mySermons',
      payload: {
        ...mySermons,
        pericope: sermon?.pericopes?.[0]?.number || 'GEN001',
      },
    });
  };

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      const elem: HTMLElement | null = e.target as HTMLElement | null;
      const aElem = elem?.tagName === 'A' ? elem : elem?.closest('a');
      if (aElem && aElem.id !== 'my-sermons-create-tab') {
        saveButtonRef.current?.click();
      }
    };

    document.addEventListener('click', handler);

    return () => {
      document.removeEventListener('click', handler);
    };
  }, [
    isMySermons,
    id,
    contentTitle,
    author,
    contentType,
    content,
    publicationTitle,
    publisher,
    yearPublished,
    hidden,
    priority,
    displayedScripture,
    scriptureReferences,
    isDraft,
  ]);

  useEffect(() => {
    reset();
  }, [sermon]);

  const [focusedReference, setFocusedReference] =
    useState<Partial<PericopeSuggestion> | null>(null);

  const [suggestions, setSuggestions] = useState<PericopeSuggestion[]>([]);
  const [selectedSuggestion, setSelectedSuggestion] =
    useState<PericopeSuggestion | null>(null);

  const suggestionsDropdown = useRef<HTMLUListElement>(null);

  const updateSuggestions = async (term: string) => {
    const res = await fetch(
      `${
        process.env.NEXT_PUBLIC_API_BASE_URL
      }/bible/pericope-suggestions?query=${encodeURIComponent(term)}`,
      {
        cache: 'force-cache',
        next: {
          tags: ['all', 'bible'],
        },
      },
    );
    const suggestionsData = (await res.json()) as PericopeSuggestion[];

    setSuggestions(suggestionsData);
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    let newSelectedSuggestion: PericopeSuggestion | null = null;
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (suggestions.length === 0) {
        updateSuggestions(focusedReference?.reference || '');
      }
      if (selectedSuggestion === null) {
        newSelectedSuggestion = suggestions[0];
      } else {
        const index = suggestions.indexOf(selectedSuggestion);
        if (index + 1 !== suggestions.length) {
          newSelectedSuggestion = suggestions[index + 1];
        }
      }
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (suggestions.length === 0) {
        updateSuggestions(focusedReference?.reference || '');
      }
      if (selectedSuggestion !== null) {
        const index = suggestions.indexOf(selectedSuggestion);
        if (index - 1 >= 0) {
          newSelectedSuggestion = suggestions[index - 1];
        }
      }
    }
    if (newSelectedSuggestion) {
      setSelectedSuggestion(newSelectedSuggestion);
      if (suggestionsDropdown.current) {
        const liElem = [
          ...suggestionsDropdown.current.querySelectorAll('li'),
        ].find((e) => e.textContent === newSelectedSuggestion!.reference);
        liElem?.scrollIntoView?.({ block: 'nearest', inline: 'start' });
      }
    }
  };

  useEffect(() => {
    const eventHandler = (e: MouseEvent) => {
      const index = scriptureReferences.findIndex(
        (scriptureReference) =>
          scriptureReference.reference === focusedReference?.reference,
      );
      const elem = document.getElementById(
        `scriptureReference-${index}`,
      ) as HTMLInputElement | null;
      if (!elem?.contains(e.target as Node)) {
        setSuggestions([]);
      }
    };

    document.addEventListener('click', eventHandler);

    return () => {
      document.removeEventListener('click', eventHandler);
    };
  }, []);

  const [tbTocTypes, setTbTocTypes] = useState<TbTocType[]>([]);

  const defaultTocType = tbTocTypes.find(
    (type) => type.tocTypeId === defaultContentType,
  );

  useEffect(() => {
    setTbTocTypes(
      isMySermons ? mySermonsContentTypes || [] : contentTypes || [],
    );
  }, [contentTypes]);

  const [loading, setLoading] = useState(false);

  const save = async () => {
    setLoading(true);
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_BASE_URL}/contents${
        isCreate && !id ? '' : `/${id}`
      }`,
      {
        method: isCreate && !id ? 'POST' : 'PATCH',
        body: JSON.stringify({
          contentTitle,
          author,
          contentType: defaultContentType || contentType,
          content,
          publicationTitle,
          publisher,
          yearPublished,
          hidden,
          priority,
          displayedScripture,
          pericopes: scriptureReferences.map((s) => s.code).filter((c) => c),
          userId: isMySermons ? user?.id : undefined,
          isDraft: isMySermons && isDraft,
          studyToolsId: isCreate ? studyToolsId : undefined,
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        cache: 'no-store',
        next: {
          tags: ['all', 'contents'].concat(id ? [`content:${id}`] : []),
        },
      },
    );

    const contentsResponse = (await res.json()) as
      | CreateResponse
      | ErrorResponse;
    if ('statusCode' in contentsResponse) {
      setError(
        Array.isArray(contentsResponse.message)
          ? contentsResponse.message.join('\n')
          : contentsResponse.message,
      );
    } else if (onSave) {
      const content = await fetchContent(
        sermon?.id || `${contentsResponse.id}`,
      );
      if (!isError(content)) {
        onSave(content);
      }
      setSuccess(contentsResponse.success);
    } else {
      setSuccess(contentsResponse.success);
    }

    setLoading(false);
  };

  return (
    <form
      className={className}
      onSubmit={async (e) => {
        e.preventDefault();
        await save();
      }}
    >
      <article>
        {!isMySermons && (
          <header className='flex justify-between border-b border-neutral-300 bg-neutral-100 p-4'>
            <h1 className='font-bold'>
              {isCreate
                ? `Adding ${defaultTocType?.tocName || 'Content'}`
                : `Editing ${
                    sermon?.contentTitle
                      ? `"${sermon.contentTitle}"`
                      : defaultTocType?.tocName || 'Content'
                  }`}
            </h1>
          </header>
        )}
        <section className='grid grid-cols-12 gap-4 bg-white p-4'>
          <div className='col-span-6'>
            <TextInput
              className='w-full'
              id='contentTitle'
              label={
                !['39'].includes(contentType) && !user?.isAdmin
                  ? 'Title *'
                  : 'Title'
              }
              required={!['39'].includes(contentType) && !user?.isAdmin}
              value={contentTitle}
              onChange={(e) => setContentTitle(e.target.value)}
            />
          </div>
          <div className='col-span-3'>
            <TextInput
              className='w-full'
              id='author'
              label='Author *'
              required
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
            />
          </div>
          <div
            className={classNames({
              'col-span-3': !['2', '9', '39'].includes(
                defaultContentType || contentType,
              ),
              'col-span-2': ['2', '9', '39'].includes(
                defaultContentType || contentType,
              ),
            })}
          >
            {defaultContentType && (
              <div className='flex size-full select-none items-center rounded border border-neutral-300 px-2 py-1'>
                {
                  tbTocTypes.find(
                    (type) => type.tocTypeId === defaultContentType,
                  )?.tocName
                }
              </div>
            )}
            {!defaultContentType && (
              <Select
                className='w-full'
                id='contentType'
                label='Content Type *'
                value={contentType}
                onChange={(e) => setContentType(e.target.value)}
                required
              >
                <option></option>
                {tbTocTypes
                  .sort((a, b) =>
                    isMySermons
                      ? MY_SERMONS_TOC_TYPES.indexOf(
                          a.tocName as MY_SERMONS_TOC_TYPE,
                        ) -
                        MY_SERMONS_TOC_TYPES.indexOf(
                          b.tocName as MY_SERMONS_TOC_TYPE,
                        )
                      : a.tocName < b.tocName
                      ? -1
                      : a.tocName === b.tocName
                      ? 0
                      : 1,
                  )
                  .map((tbTocType) => (
                    <option
                      key={tbTocType.tocTypeId}
                      value={tbTocType.tocTypeId}
                    >
                      {tbTocType.tocName}
                    </option>
                  ))}
              </Select>
            )}
          </div>
          <div
            className={classNames({
              hidden: !['2', '9', '39'].includes(
                defaultContentType || contentType,
              ),
            })}
          >
            <Select
              className='w-full'
              label='Priority'
              onChange={(e) =>
                e.target.value === ''
                  ? setPriority(null)
                  : setPriority(+e.target.value)
              }
              value={priority === null ? '' : priority}
            >
              <option value=''>-</option>
              {Array(5)
                .fill(null)
                .map((_, i) => (
                  <option key={i} value={i}>
                    {i}
                  </option>
                ))}
            </Select>
          </div>
          <div className='col-span-12'>
            <MyEditor
              data={content}
              onChange={(_, editor) => {
                setContent(editor.getData());
              }}
              onReady={(editor) => {
                const elem = editor.ui.getEditableElement();
                const parentElement = elem?.parentElement;
                if (parentElement) {
                  parentElement.classList.add('sermon-content');
                  parentElement.style.height =
                    'calc(100dvh - 4.25rem - 1rem - 2.25rem - 1rem - 40px - 1rem  - 2.25rem - 1rem - 2.25rem - 4.5rem - 4rem - 2.5rem)';
                  parentElement.style.minHeight = '45.2px';
                }
              }}
              onFocus={() => {
                if (clearOnFocus) {
                  setContent('');
                }
                onFocus?.();
              }}
            />
          </div>
          <div className='col-span-5'>
            <TextInput
              className='w-full'
              id='publicationTitle'
              label='Publication Title'
              value={publicationTitle}
              onChange={(e) => setPublicationTitle(e.target.value)}
            />
          </div>
          <div className='col-span-5'>
            <TextInput
              className='w-full'
              id='publisher'
              label='Publisher'
              value={publisher}
              onChange={(e) => setPublisher(e.target.value)}
            />
          </div>
          <div className='col-span-2'>
            <TextInput
              className='w-full'
              id='yearPublished'
              label='Year'
              type='number'
              value={yearPublished}
              onChange={(e) => setYearPublished(e.target.value)}
            />
          </div>
          <div className='col-span-6'>
            <TextInput
              className='w-full'
              id='displayedScripture'
              label='Displayed Scripture'
              value={displayedScripture || ''}
              onChange={(e) => setDisplayedScripture(e.target.value)}
            />
          </div>
          <div className='col-span-6 grid grid-cols-1 gap-4'>
            {scriptureReferences.map((scriptureReference, index) => (
              <div key={`${scriptureReference.code}-${index}`}>
                <div className='relative'>
                  <TextInput
                    className='w-full'
                    containerClassName='inline-block w-[calc(100%_-_3.25rem)]'
                    id={`scriptureReference-${index}`}
                    label={
                      ['1', '43'].includes(contentType)
                        ? 'Scripture Reference *'
                        : 'Scripture Reference'
                    }
                    required={['1', '43'].includes(contentType)}
                    value={scriptureReference.reference}
                    onFocus={(e) => {
                      setFocusedReference(scriptureReference);
                      updateSuggestions(scriptureReference?.reference || '');
                      e.target.select();
                    }}
                    onChange={(e) => {
                      setFocusedReference(scriptureReference);
                      const newScriptureReferences = [...scriptureReferences];
                      newScriptureReferences[index].code = '';
                      newScriptureReferences[index].reference = e.target.value;
                      setScriptureReferences(newScriptureReferences);
                      updateSuggestions(e.target.value);
                    }}
                    onKeyDown={onKeyDown}
                    autoComplete='off'
                  />
                  {focusedReference === scriptureReference &&
                    suggestions &&
                    suggestions.length > 0 && (
                      <ul
                        className='absolute left-0 top-9 z-50 inline-block max-h-[calc(100vh_-_128px)] overflow-auto border border-neutral-300 bg-white'
                        ref={suggestionsDropdown}
                      >
                        {suggestions?.map((suggestion) => (
                          <li
                            key={suggestion.reference}
                            className={classNames(
                              'cursor-pointer px-2 hover:bg-sermons-light hover:text-white',
                              {
                                'bg-sermons-dark text-white':
                                  suggestion === selectedSuggestion,
                              },
                            )}
                            onClick={() => {
                              setSelectedSuggestion(suggestion);
                              setSuggestions([]);
                              const newScriptureReferences = [
                                ...scriptureReferences,
                              ];
                              newScriptureReferences[index].reference =
                                suggestion.reference;
                              newScriptureReferences[index].code =
                                suggestion.code;
                              setScriptureReferences(newScriptureReferences);
                              dispatch({
                                type: 'mySermons',
                                payload: {
                                  ...mySermons,
                                  pericope:
                                    newScriptureReferences[0].code || 'GEN001',
                                },
                              });
                            }}
                          >
                            {suggestion.reference}
                          </li>
                        ))}
                      </ul>
                    )}
                  {index === 0 && (
                    <Button
                      className='ml-4 inline-flex size-9 items-center justify-center align-bottom'
                      type='button'
                      onClick={() => {
                        const newScriptureReferences = [
                          ...scriptureReferences,
                          {
                            code: '',
                            reference: '',
                          },
                        ];
                        setScriptureReferences(newScriptureReferences);
                      }}
                    >
                      <BsPlusLg className='inline-block text-2xl' />
                    </Button>
                  )}
                  {index !== 0 && (
                    <Button
                      buttonType='red'
                      className='ml-4 inline-flex size-9 items-center justify-center align-bottom'
                      type='button'
                      onClick={() => {
                        const newScriptureReferences = [...scriptureReferences];
                        newScriptureReferences.splice(index, 1);
                        setScriptureReferences(newScriptureReferences);
                      }}
                    >
                      <BsTrash className='inline-block text-2xl' />
                    </Button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </section>
        <footer className='flex justify-between border-t border-neutral-300 bg-neutral-100 p-4'>
          <div>
            {isMySermons && (
              <Button
                className='mr-4'
                type='button'
                onClick={() => {
                  if (onCreate) {
                    onCreate();
                  }
                }}
              >
                New
              </Button>
            )}
            <Button
              type='button'
              onClick={() => {
                const inputElem = document.createElement('input');
                inputElem.type = 'file';
                inputElem.click();
                inputElem.onchange = async () => {
                  if (!inputElem.files?.[0]) {
                    return;
                  }
                  const formData = new FormData();
                  formData.set('file', inputElem.files[0]);

                  if (onCreate) {
                    onCreate();
                  }

                  setTimeout(async () => {
                    try {
                      const html = await convertToHtml(formData);
                      setContent(html);
                    } catch (e) {
                      if (e instanceof Error) {
                        setError(e.message);
                        setTimeout(() => setError(''), 5000);
                      }
                    }
                  });
                };
              }}
            >
              Upload
            </Button>
          </div>
          <div>
            {isMySermons && (
              <div className='mr-4 inline-block'>
                <label className='mr-4'>
                  <input
                    type='radio'
                    name='draft'
                    checked={isDraft}
                    onChange={(e) => setIsDraft(e.target.checked)}
                  />{' '}
                  Draft
                </label>
                <label>
                  <input
                    type='radio'
                    name='draft'
                    checked={!isDraft}
                    onChange={(e) => setIsDraft(!e.target.checked)}
                  />{' '}
                  Completed
                </label>
              </div>
            )}
            <Button
              type='submit'
              className={classNames('relative h-9 overflow-hidden', {
                loading: loading,
                'mr-4': !isMySermons,
              })}
              disabled={loading}
              ref={saveButtonRef}
            >
              <div className='loading-bar absolute inset-x-0 top-0 mx-auto h-1 w-full bg-neutral-300'>
                <div className='spinner relative h-1 bg-sermons-dark'></div>
              </div>
              Save
            </Button>
            {!isMySermons && (
              <Button
                type='button'
                className='h-9'
                buttonType='flat-white'
                onClick={() => {
                  reset();
                  const names = author
                    .replaceAll(/\s+/g, ' ')
                    .replaceAll(/^\s+|\s+$/g, '')
                    .split(' ');
                  const lastName = names.pop();
                  const firstName = names.join(' ');
                  if (onCancel) {
                    onCancel({
                      id: sermon?.id || '0',
                      authors: lastName
                        ? [
                            {
                              id: 0,
                              firstname: firstName,
                              lastname: lastName,
                            },
                          ]
                        : undefined,
                      contentTitle,
                      slug: sermon?.slug || slugify(contentTitle || '_'),
                      content,
                      truncatedContent: null,
                      contentType,
                      hidden,
                      // createdDate:
                      // sermon?.createdDate || new Date().toISOString(),
                      displayedScripture: displayedScripture
                        ? [
                            {
                              id: 0,
                              sermonId: '0',
                              displayedScripture,
                              needsVerification: false,
                            },
                          ]
                        : undefined,
                      isbn: '',
                      publicationTitle,
                      publisher,
                      yearPublished,
                      extraData: priority
                        ? {
                            priority,
                          }
                        : undefined,
                      pericopes: scriptureReferences.map(
                        (sr) =>
                          ({
                            number: sr.code,
                            fullReference: sr.reference,
                          }) as PericopeWithVerses,
                      ),
                      // lastUpdated: new Date().toISOString(),
                      isDraft,
                      ...sermon,
                    });
                  }
                }}
              >
                {cancelText || 'Cancel'}
              </Button>
            )}
          </div>
        </footer>
      </article>
    </form>
  );
}
