'use client';

import Loading from '@/app/(dynamic)/loading';
import {
  BIBLE_TRANSLATION,
  BIBLE_TRANSLATIONS,
  fetchTextAndTools,
  hasContent,
  verseReducer,
} from '@/lib/helpers';
import {
  NewDictionaryWordsPericope,
  NewWeek,
  StudyTools,
  ThisWeek,
} from '@/types/sermons';
import { default as classNames, default as cx } from 'classnames';
import { useEffect, useRef, useState } from 'react';
import {
  BsCaretDownFill,
  BsCaretLeft,
  BsCaretLeftFill,
  BsCaretRight,
  BsCaretRightFill,
} from 'react-icons/bs';
import Accordion from '../molecules/accordion';
import Button from '../molecules/button';
import LoggedOutMessage from '../molecules/logged-out-message';
import { useAppState } from '../providers/state-provider';

export default function TextAndTools({
  thisWeek,
  studyTools,
  isMySermons,
  week,
}: {
  thisWeek?: ThisWeek | null;
  studyTools?: StudyTools | null;
  isMySermons?: boolean;
  week?: NewWeek;
}) {
  const {
    state: { user, token, isLoading, isBrowser },
  } = useAppState();

  const [selection, setSelection] = useState<'ot' | 'ps' | 'ep' | 'gs'>('gs');

  const [bibleDropdownOpen, setBibleDropdownOpen] = useState(false);

  const bibleDropdownButtonRef = useRef<HTMLButtonElement>(null);

  const [bibleType, setBibleType] = useState<BIBLE_TRANSLATION>(
    user?.translation || 'niv',
  );

  const [dynamicStudyTools, setDynamicStudyTools] = useState(studyTools);

  const selectedTools = thisWeek
    ? thisWeek.studyTools[selection]!
    : dynamicStudyTools!;

  const paragraphs: JSX.Element[][] = (selectedTools.scripture || []).reduce(
    verseReducer(bibleType),
    [] as JSX.Element[][],
  );

  useEffect(() => {
    const eventHandler = (e: MouseEvent) => {
      if (!bibleDropdownButtonRef.current?.contains(e.target as Node)) {
        setBibleDropdownOpen(false);
      }
    };
    document.addEventListener('click', eventHandler);

    return () => {
      document.removeEventListener('click', eventHandler);
    };
  }, []);

  const [selectedNewDictionaryWord, setSelectedNewDictionaryWord] =
    useState<NewDictionaryWordsPericope>();

  const hasSubscription = !!user?.hasIllustrations;

  const bibleHeadingRef = useRef<HTMLDivElement>(null);
  const [needsShortHeading, setNeedsShortHeading] = useState(false);
  useEffect(() => {
    if (
      bibleHeadingRef.current &&
      bibleHeadingRef.current.scrollWidth >
        bibleHeadingRef.current.clientWidth &&
      needsShortHeading === false
    ) {
      setNeedsShortHeading(true);
    } else if (needsShortHeading === true) {
      setNeedsShortHeading(false);
    }
  });

  const weekPericopes = week?.bibleCode
    ?.split(' or ')
    .map((str) => str.split(','));
  const codes = weekPericopes?.find((arr) =>
    arr.some((code) => code === dynamicStudyTools?.bible.number),
  );
  const [codeIndex, setCodeIndex] = useState(
    dynamicStudyTools
      ? (codes || []).indexOf(dynamicStudyTools.bible.number)
      : -1,
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (codes?.[codeIndex] && token) {
      const code = codes[codeIndex];
      if (dynamicStudyTools?.bible.number !== code) {
        setLoading(true);
        (async () => {
          const result = await fetchTextAndTools(code, token);
          if (!('statusCode' in result)) {
            setDynamicStudyTools(result.studyTools);
            setLoading(false);
          }
        })().catch(console.error);
      }
    }
  }, [codeIndex, token]);

  const prevPericope = codeIndex > 0 ? codes?.[codeIndex - 1] : undefined;
  const nextPericope = codeIndex >= 0 ? codes?.[codeIndex + 1] : undefined;

  return (
    <section>
      {!isMySermons && (
        <div className='mb-4 text-center font-bold'>
          {prevPericope && dynamicStudyTools && (
            <BsCaretLeftFill
              className='relative top-[-2px] mr-1 inline-block cursor-pointer text-sermons-dark'
              onClick={() => setCodeIndex(codeIndex - 1)}
            />
          )}
          {!prevPericope && dynamicStudyTools && (
            <BsCaretLeft className='relative top-[-2px] mr-1 inline-block cursor-not-allowed text-sermons-dark' />
          )}
          <span>Text and Tools</span>
          {nextPericope && dynamicStudyTools && (
            <BsCaretRightFill
              className='relative top-[-2px] ml-1 inline-block cursor-pointer text-sermons-dark'
              onClick={() => setCodeIndex(codeIndex + 1)}
            />
          )}
          {!nextPericope && dynamicStudyTools && (
            <BsCaretRight className='relative top-[-2px] ml-1 inline-block cursor-not-allowed text-sermons-dark' />
          )}
        </div>
      )}
      {week && (
        <div className='-mt-4 mb-4 text-center font-bold'>
          {week.serviceNameFull}
          <br />
          {week.citationL}
        </div>
      )}
      {loading && <Loading />}
      {!loading && (
        <>
          {thisWeek && (
            <>
              <div className='mb-4 text-center'>
                {thisWeek.studyTools.gs && (
                  <Button
                    buttonType={selection === 'gs' ? 'default' : 'flat-blue'}
                    className='-mb-px -mr-px !rounded-none !rounded-l border border-sermons-dark px-2 py-1'
                    onClick={() => setSelection('gs')}
                  >
                    {isMySermons
                      ? thisWeek.studyTools.gs.bible.abbrev
                      : thisWeek.studyTools.gs.bible.fullReference}
                  </Button>
                )}
                {thisWeek.studyTools.ep && (
                  <Button
                    buttonType={selection === 'ep' ? 'default' : 'flat-blue'}
                    className='-mb-px -mr-px !rounded-none border border-sermons-dark px-2 py-1'
                    onClick={() => setSelection('ep')}
                  >
                    {isMySermons
                      ? thisWeek.studyTools.ep.bible.abbrev
                      : thisWeek.studyTools.ep.bible.fullReference}
                  </Button>
                )}
                {thisWeek.studyTools.ot && (
                  <Button
                    buttonType={selection === 'ot' ? 'default' : 'flat-blue'}
                    className='-mb-px -mr-px !rounded-none border border-sermons-dark px-2 py-1'
                    onClick={() => setSelection('ot')}
                  >
                    {isMySermons
                      ? thisWeek.studyTools.ot.bible.abbrev
                      : thisWeek.studyTools.ot.bible.fullReference}
                  </Button>
                )}
                {thisWeek.studyTools.ps && (
                  <Button
                    buttonType={selection === 'ps' ? 'default' : 'flat-blue'}
                    className='!rounded-none !rounded-r border border-sermons-dark px-2 py-1'
                    onClick={() => setSelection('ps')}
                  >
                    {isMySermons
                      ? thisWeek.studyTools.ps.bible.abbrev
                      : thisWeek.studyTools.ps.bible.fullReference}
                  </Button>
                )}
              </div>
              <div className='flex items-center justify-center'>
                <Button
                  type='button'
                  className='relative flex h-8 items-center justify-center overflow-visible'
                  onClick={() => setBibleDropdownOpen(!bibleDropdownOpen)}
                  ref={bibleDropdownButtonRef}
                >
                  {bibleType.toUpperCase()} <BsCaretDownFill className='ml-1' />
                  <nav
                    className={cx(
                      'absolute left-0 top-8 z-50 border border-neutral-300 bg-white text-sm',
                      {
                        hidden: !bibleDropdownOpen,
                      },
                    )}
                  >
                    <ul className='grid grid-cols-1'>
                      {BIBLE_TRANSLATIONS.map((translation) => (
                        <li
                          key={translation}
                          className='cursor-pointer text-sermons-dark hover:bg-neutral-100 hover:underline'
                        >
                          <span
                            className='inline-block w-full px-4 py-1'
                            onClick={() => setBibleType(translation)}
                          >
                            {translation.toUpperCase()}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </Button>
              </div>
              <div
                className={classNames(
                  'mb-2 overflow-hidden whitespace-nowrap font-bold',
                  {
                    'mt-4 text-center': isMySermons && !needsShortHeading,
                    'h-0': needsShortHeading,
                  },
                )}
                ref={bibleHeadingRef}
              >
                {dynamicStudyTools && (
                  <span>{selectedTools.bible.fullReference} &middot; </span>
                )}
                {selectedTools.bible.heading}
              </div>
              <div
                className={classNames('mb-2 font-bold', {
                  'mt-4 text-center': isMySermons,
                  hidden: !needsShortHeading,
                })}
              >
                {dynamicStudyTools && (
                  <span>{selectedTools.bible.abbrev} &middot; </span>
                )}
                {selectedTools.bible.shortHeading}
              </div>
              <section className='sermon-content'>
                {paragraphs.map((paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                ))}
              </section>
            </>
          )}
          {!thisWeek && selectedTools && (
            <>
              <div
                className={classNames(
                  'mb-2 overflow-hidden whitespace-nowrap font-bold',
                  {
                    'mt-4 text-center': isMySermons && !needsShortHeading,
                    'h-0': needsShortHeading,
                  },
                )}
                ref={bibleHeadingRef}
              >
                {dynamicStudyTools && (
                  <span>{selectedTools.bible.fullReference} &middot; </span>
                )}
                {selectedTools.bible.heading}
              </div>
              <div
                className={classNames('mb-2 font-bold', {
                  'mt-4 text-center': isMySermons,
                  hidden: !needsShortHeading,
                })}
              >
                {dynamicStudyTools && (
                  <span>{selectedTools.bible.abbrev} &middot; </span>
                )}
                {selectedTools.bible.shortHeading}
              </div>
              <section className='sermon-content'>
                {paragraphs.map((paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                ))}
              </section>
            </>
          )}
          <hr className='my-4' />
          {selectedTools.handbook && (
            <>
              <div>
                <h2 className='font-bold'>Overview and Insights</h2>
                <div
                  className='sermon-content'
                  dangerouslySetInnerHTML={{
                    __html:
                      (!isLoading &&
                        user &&
                        hasContent(user, selectedTools.handbook)) ||
                      !isBrowser
                        ? selectedTools.handbook.content
                        : selectedTools.handbook.truncatedContent || '',
                  }}
                ></div>
                {(!user || !hasSubscription || user.isExpired) &&
                  !isLoading &&
                  isBrowser && <LoggedOutMessage />}
                <div className='flex items-center justify-end'>
                  <span>
                    <span className='underline'>
                      {selectedTools.handbook.publicationTitle}
                    </span>
                    {selectedTools.handbook.authors && (
                      <span>
                        {' '}
                        by{' '}
                        {selectedTools.handbook.authors.map((author, index) => (
                          <span key={author.id}>
                            {index > 0 && <span>,</span>} {author.firstname}{' '}
                            {author.lastname}
                          </span>
                        ))}
                        , {selectedTools.handbook.publisher},{' '}
                        {selectedTools.handbook.yearPublished}
                      </span>
                    )}
                  </span>
                </div>
              </div>
              <hr className='my-4' />
            </>
          )}
          {selectedTools.ovc && (
            <>
              <div>
                <h2 className='font-bold'>Baker Commentary</h2>
                <div
                  className='sermon-content'
                  dangerouslySetInnerHTML={{
                    __html:
                      (!isLoading &&
                        user &&
                        hasContent(user, selectedTools.ovc)) ||
                      !isBrowser
                        ? selectedTools.ovc.content
                        : selectedTools.ovc.truncatedContent || '',
                  }}
                ></div>
                {(!user || !hasSubscription || user.isExpired) &&
                  !isLoading &&
                  isBrowser && <LoggedOutMessage />}
                <div className='flex items-center justify-end'>
                  <span>
                    <span className='underline'>
                      {selectedTools.ovc.publicationTitle}
                    </span>
                    {selectedTools.ovc.authors && (
                      <span>
                        {' '}
                        by{' '}
                        {selectedTools.ovc.authors.map((author, index) => (
                          <span key={author.id}>
                            {index > 0 && <span>,</span>} {author.firstname}{' '}
                            {author.lastname}
                          </span>
                        ))}
                        , {selectedTools.ovc.publisher},{' '}
                        {selectedTools.ovc.yearPublished}
                      </span>
                    )}
                  </span>
                </div>
              </div>
              <hr className='my-4' />
            </>
          )}
          <div>
            {selectedTools.directMatchesExhaustive.length > 0 && (
              <h2 className='font-bold'>Dictionary Terms</h2>
            )}
            <h3>Direct Matches</h3>
            {selectedTools.directMatchesExhaustive.map((dwP) => (
              <div key={dwP.id} className='m-4'>
                <Accordion
                  header={dwP.word.word}
                  isOpen={selectedNewDictionaryWord === dwP}
                  onToggle={(isOpen) => {
                    if (isOpen) {
                      setSelectedNewDictionaryWord(dwP);
                    }
                  }}
                >
                  <div
                    className='sermon-content'
                    dangerouslySetInnerHTML={{
                      __html:
                        (!user || !hasSubscription || user.isExpired) &&
                        !isLoading &&
                        isBrowser
                          ? dwP.word.definitionTruncated || ''
                          : dwP.word.definition,
                    }}
                  ></div>
                  {(!user || !hasSubscription || user.isExpired) &&
                    !isLoading &&
                    isBrowser && <LoggedOutMessage />}
                </Accordion>
              </div>
            ))}
            {selectedTools.secondaryMatchesExhaustive.length > 0 && (
              <h3>Secondary Matches</h3>
            )}
            <div className='text-sm italic'>
              The following suggestions occured because{' '}
              {selectedTools.bible.fullReference} is mentioned in the
              definition.
            </div>
            {selectedTools.secondaryMatchesExhaustive.map((dwP) => (
              <div key={dwP.id} className='m-4'>
                <Accordion
                  header={dwP.word.word}
                  isOpen={selectedNewDictionaryWord === dwP}
                  onToggle={(isOpen) => {
                    if (isOpen) {
                      setSelectedNewDictionaryWord(dwP);
                    }
                  }}
                >
                  <div
                    className='sermon-content'
                    dangerouslySetInnerHTML={{
                      __html:
                        (!user || !hasSubscription || user.isExpired) &&
                        !isLoading &&
                        isBrowser
                          ? dwP.word.definitionTruncated || ''
                          : dwP.word.definition,
                    }}
                  ></div>
                  {(!user || !hasSubscription || user.isExpired) &&
                    !isLoading &&
                    isBrowser && <LoggedOutMessage />}
                </Accordion>
              </div>
            ))}
          </div>
          <hr className='my-4' />
        </>
      )}
    </section>
  );
}
