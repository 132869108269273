'use client';

import cx from 'classnames';
import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';
import { DetailedHTMLProps, HTMLAttributes, useEffect, useState } from 'react';

export default function SermonAidsTabs({
  sermonAidsTotal,
  bulletinAidsTotal,
  className,
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> & {
  sermonAidsTotal: number;
  bulletinAidsTotal: number;
}) {
  const [tab, setTab] = useState<'Sermon Aids' | 'Bulletin Aids'>('Bulletin Aids');

  const pathname = usePathname();
  const searchParams = useSearchParams();

  const queryStrings: string[] = [];
  for (const [key, value] of searchParams.entries()) {
    if (!['tab', 'page', 'saPage', 'baPage'].includes(key)) {
      queryStrings.push(`${key}=${encodeURIComponent(value)}`);
    }
  }
  let queryString = `?${queryStrings.join('&')}`;
  if (queryString !== '?') {
    queryString += '&';
  }

  const qTab = searchParams.get('tab');
  useEffect(() => {
    if ([null, 'Sermon Aids', 'Bulletin Aids'].includes(qTab)) {
      setTab(qTab as 'Sermon Aids' | 'Bulletin Aids' || 'Bulletin Aids');
    }
  }, [qTab]);

  return (
    <nav className={cx('w-full overflow-auto bg-sermons-dark text-white font-bold h-10', className)} {...props}>
      <div className='grid min-w-[672px] grid-cols-5 whitespace-nowrap'>
        <div className={cx('flex h-10 items-center justify-center border-b-4 cursor-pointer', {
          'border-b-sermons-highlight': tab === 'Bulletin Aids',
          'border-b-sermons-dark': tab !== 'Bulletin Aids',
        })}>
          <Link className='flex h-10 w-full items-center justify-center' href={`${pathname}${queryString}tab=Bulletin%20Aids`}>
            Bulletin Aids <span className='relative -top-1 left-1 align-super text-xs'>{bulletinAidsTotal}</span>
          </Link>
        </div>
        <div className={cx('flex h-10 items-center justify-center border-b-4 cursor-pointer', {
          'border-b-sermons-highlight': tab === 'Sermon Aids',
          'border-b-sermons-dark': tab !== 'Sermon Aids',
        })}>
          <Link className='flex h-10 w-full items-center justify-center' href={`${pathname}${queryString}tab=Sermon%20Aids`}>
            Sermon Aids <span className='relative -top-1 left-1 align-super text-xs'>{sermonAidsTotal}</span>
          </Link>
        </div>
      </div>
    </nav>
  )
}
