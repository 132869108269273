'use client';

import { ProductType } from '@/types/sermons';
import cx from 'classnames';
import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';
import { DetailedHTMLProps, HTMLAttributes, useEffect, useState } from 'react';

export default function MediaTabs({
  wbTotal,
  tgTotal,
  uwTotal,
  mnTotal,
  cnTotal,
  className,
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> & {
  wbTotal: number;
  tgTotal: number;
  uwTotal: number;
  mnTotal: number;
  cnTotal: number;
}) {
  const [tab, setTab] = useState<ProductType>('Title Graphics');

  const pathname = usePathname();
  const searchParams = useSearchParams();

  const queryStrings: string[] = [];
  for (const [key, value] of searchParams.entries()) {
    if (!['tab', 'page'].includes(key)) {
      queryStrings.push(`${key}=${encodeURIComponent(value)}`);
    }
  }
  let queryString = `?${queryStrings.join('&')}`;
  if (queryString !== '?') {
    queryString += '&';
  }

  const qTab = searchParams.get('tab');
  useEffect(() => {
    if ([null, 'Worship Backgrounds', 'Title Graphics', 'Ultra-Wides', 'Motions', 'Countdowns'].includes(qTab)) {
      setTab(qTab as ProductType || 'Title Graphics');
    }
  }, [qTab]);

  return (
    <nav className={cx('w-full overflow-auto bg-sermons-dark text-white font-bold h-10', className)} {...props}>
      <div className='grid min-w-[672px] grid-cols-5 whitespace-nowrap'>
        <div className={cx('flex h-10 items-center justify-center border-b-4 cursor-pointer', {
          'border-b-sermons-highlight': tab === 'Title Graphics',
          'border-b-sermons-dark': tab !== 'Title Graphics',
        })}>
          <Link className='flex h-10 w-full items-center justify-center' href={`${pathname}${queryString}tab=Title Graphics`}>
            Title Graphics <span className='relative -top-1 left-1 align-super text-xs'>{tgTotal}</span>
          </Link>
        </div>
        <div className={cx('flex h-10 items-center justify-center border-b-4 cursor-pointer', {
          'border-b-sermons-highlight': tab === 'Ultra-Wides',
          'border-b-sermons-dark': tab !== 'Ultra-Wides',
        })}>
          <Link className='flex h-10 w-full items-center justify-center' href={`${pathname}${queryString}tab=Ultra-Wides`}>
            Ultra-Wides <span className='relative -top-1 left-1 align-super text-xs'>{uwTotal}</span>
          </Link>
        </div>
        <div className={cx('flex h-10 items-center justify-center border-b-4 cursor-pointer', {
          'border-b-sermons-highlight': tab === 'Worship Backgrounds',
          'border-b-sermons-dark': tab !== 'Worship Backgrounds',
        })}>
          <Link className='flex h-10 w-full items-center justify-center' href={`${pathname}${queryString}tab=Worship Backgrounds`}>
            Worship Backgrounds <span className='relative -top-1 left-1 align-super text-xs'>{wbTotal}</span>
          </Link>
        </div>
        <div className={cx('flex h-10 items-center justify-center border-b-4 cursor-pointer', {
          'border-b-sermons-highlight': tab === 'Motions',
          'border-b-sermons-dark': tab !== 'Motions',
        })}>
          <Link className='flex h-10 w-full items-center justify-center' href={`${pathname}${queryString}tab=Motions`}>
            Motions <span className='relative -top-1 left-1 align-super text-xs'>{mnTotal}</span>
          </Link>
        </div>
        <div className={cx('flex h-10 items-center justify-center border-b-4 cursor-pointer', {
          'border-b-sermons-highlight': tab === 'Countdowns',
          'border-b-sermons-dark': tab !== 'Countdowns',
        })}>
          <Link className='flex h-10 w-full items-center justify-center' href={`${pathname}${queryString}tab=Countdowns`}>
            Countdowns <span className='relative -top-1 left-1 align-super text-xs'>{cnTotal}</span>
          </Link>
        </div>
      </div>
    </nav>
  )
}
