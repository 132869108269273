'use client';

import {
  Content,
  ScriptureSearchResults,
  SeriesCategoriesResponse,
} from '@/types/sermons';
import SermonBible from '../molecules/sermon-bible';
import SermonContent from '../molecules/sermon-content';
import SermonIllustrations from '../molecules/sermon-illustrations';
import SermonNews from '../molecules/sermon-news';
import SermonPrepBar from '../molecules/sermon-prep-bar';
import SermonTools from '../molecules/sermon-tools';

export interface ContentSlugResponse {
  slug: string;
}

export default function Sermon({
  sermon,
  illustrationsResults,
  className,
  noResults,
  mySermonsMessage,
  seriesCategories,
}: {
  sermon: Content;
  illustrationsResults?: ScriptureSearchResults;
  className?: string;
  noResults?: boolean;
  mySermonsMessage?: string;
  seriesCategories?: SeriesCategoriesResponse;
}) {
  return (
    <>
      <SermonPrepBar className={className} sermon={sermon} />
      <SermonContent
        sermon={sermon}
        seriesCategories={seriesCategories}
        noResults={noResults}
        mySermonsMessage={mySermonsMessage}
      />
      <SermonTools sermon={sermon} />
      <SermonBible sermon={sermon} />
      <SermonIllustrations
        sermon={sermon}
        illustrationsResults={illustrationsResults}
      />
      <SermonNews sermon={sermon} />
    </>
  );
}
