'use client';

import { toQueryString } from '@/lib/helpers';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useAppState } from '../providers/state-provider';

export default function DropdownLink({
  path,
  onClick,
  linkText,
  paramSetup,
}: {
  path?: string;
  onClick?: () => void;
  linkText: string;
  paramSetup?: (searchParams: URLSearchParams) => URLSearchParams;
}) {
  const {
    state: { searchParams },
  } = useAppState();

  const pathname = usePathname();

  return (
    <Link
      className='inline-block w-full px-4 py-1'
      href={`${typeof path === 'string' ? path : pathname}${toQueryString(
        paramSetup
          ? paramSetup(new URLSearchParams(searchParams))
          : searchParams,
      )}`}
      onClick={onClick}
    >
      {linkText.toUpperCase()}
    </Link>
  );
}
