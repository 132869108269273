'use client';

import { Content } from '@/types/sermons';
import classNames from 'classnames';
import { useAppState } from '../providers/state-provider';

export default function SermonNews({ sermon }: { sermon: Content }) {
  const {
    state: { searchParams, sermonPrepTab },
  } = useAppState();

  const pericopeCode = searchParams.get('pericopeCode');

  const pericope = sermon.pericopes?.filter(
    (p) => !pericopeCode || p.number === pericopeCode,
  )?.[0];

  return (
    [1, 8, 10, 15, 16, 17, 19, 24, 25, 28, 29, 35].includes(
      +sermon.contentType,
    ) &&
    pericope && (
      <section
        className={classNames({
          hidden: sermonPrepTab !== 'news',
        })}
      >
        <div className='grid grid-cols-1 gap-4 pt-4 lg:grid-cols-2'>
          {sermon.rss?.christianpost && sermon.rss?.christianpost.items && (
            <section className='rounded-t border border-neutral-300'>
              <header className='rounded-t border-b border-neutral-300 bg-neutral-100 px-4 py-2'>
                {sermon.rss?.christianpost.title}
              </header>
              <article className='p-4'>
                <ul className='ml-4 list-disc text-sm'>
                  {sermon.rss?.christianpost.items.slice(0, 10).map((item) => (
                    <li key={item.guid}>
                      <a
                        className='cursor-pointer font-serif italic hover:underline'
                        href={item.link}
                        rel='noopener noreferrer nofollow'
                        target='_blank'
                      >
                        {item.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </article>
            </section>
          )}
          {sermon.rss?.christianitytoday &&
            sermon.rss?.christianitytoday.items && (
              <section className='rounded-t border border-neutral-300'>
                <header className='rounded-t border-b border-neutral-300 bg-neutral-100 px-4 py-2'>
                  {sermon.rss?.christianitytoday.title}
                </header>
                <article className='p-4'>
                  <ul className='ml-4 list-disc text-sm'>
                    {sermon.rss?.christianitytoday.items
                      .slice(0, 10)
                      .map((item) => (
                        <li key={item.guid}>
                          <a
                            className='cursor-pointer font-serif italic hover:underline'
                            href={item.link}
                            rel='noopener noreferrer nofollow'
                            target='_blank'
                          >
                            {item.title}
                          </a>
                        </li>
                      ))}
                  </ul>
                </article>
              </section>
            )}
          {sermon.rss?.flipboard && sermon.rss?.flipboard.items && (
            <section className='rounded-t border border-neutral-300'>
              <header className='rounded-t border-b border-neutral-300 bg-neutral-100 px-4 py-2'>
                {sermon.rss?.flipboard.title}
              </header>
              <article className='p-4'>
                <ul className='ml-4 list-disc text-sm'>
                  {sermon.rss?.flipboard.items.slice(0, 10).map((item) => (
                    <li key={item.guid}>
                      <a
                        className='cursor-pointer font-serif italic hover:underline'
                        href={item.link}
                        rel='noopener noreferrer nofollow'
                        target='_blank'
                      >
                        {item.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </article>
            </section>
          )}
          {sermon.rss?.archaeology && sermon.rss?.archaeology.items && (
            <section className='rounded-t border border-neutral-300'>
              <header className='rounded-t border-b border-neutral-300 bg-neutral-100 px-4 py-2'>
                {sermon.rss?.archaeology.title}
              </header>
              <article className='p-4'>
                <ul className='ml-4 list-disc text-sm'>
                  {sermon.rss?.archaeology.items.slice(0, 10).map((item) => (
                    <li key={item.guid}>
                      <a
                        className='cursor-pointer font-serif italic hover:underline'
                        href={item.link}
                        rel='noopener noreferrer nofollow'
                        target='_blank'
                      >
                        {item.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </article>
            </section>
          )}
          {sermon.rss?.realclearreligion &&
            sermon.rss?.realclearreligion.items && (
              <section className='rounded-t border border-neutral-300'>
                <header className='rounded-t border-b border-neutral-300 bg-neutral-100 px-4 py-2'>
                  {sermon.rss?.realclearreligion.title}
                </header>
                <article className='p-4'>
                  <ul className='ml-4 list-disc text-sm'>
                    {sermon.rss?.realclearreligion.items
                      .slice(0, 10)
                      .map((item) => (
                        <li key={item.guid}>
                          <a
                            className='cursor-pointer font-serif italic hover:underline'
                            href={item.link}
                            rel='noopener noreferrer nofollow'
                            target='_blank'
                          >
                            {item.title}
                          </a>
                        </li>
                      ))}
                  </ul>
                </article>
              </section>
            )}
        </div>
      </section>
    )
  );
}
