'use client';

import '@/app/(dynamic)/user/signup/loading.css';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import { BsPlus } from 'react-icons/bs';
import ContentEditDialog from '../admin/content/content-edit-dialog';
import { useNotifications } from '../providers/notification-provider';
import Button from './button';

export default function AddIllustration() {
  const addNotification = useNotifications();

  const router = useRouter();

  const [createOpen, setCreateOpen] = useState(false);

  return (
    <>
      <Button
        className='flex size-6 items-center justify-center !p-0'
        onClick={() => setCreateOpen(true)}
      >
        <BsPlus className='text-xl' strokeWidth={1.25} />
      </Button>
      <ContentEditDialog
        isOpen={createOpen}
        setOpen={setCreateOpen}
        setError={(message) => addNotification({ type: 'error', message })}
        setSuccess={(message) => addNotification({ type: 'error', message })}
        onSave={() => {
          router.refresh();
        }}
        isCreate
        contentType={'2'}
      />
    </>
  );
}
