import { useAppState } from '@/components/providers/state-provider';
import { useCallback } from 'react';

export default function useUpdatedUrl() {
  const {
    state: { isBrowser },
  } = useAppState();

  const updatedUrl = useCallback(
    (updatedParam?: Record<string, string | null>) => {
      if (!isBrowser) {
        return process.env.NEXT_PUBLIC_WEB_BASE_URL || '';
      }

      const currentUrl = new URL(window.location.href);

      if (updatedParam) {
        Object.keys(updatedParam).forEach((key) => {
          if (updatedParam[key] !== null) {
            currentUrl.searchParams.set(key, updatedParam[key] as string);
          } else {
            currentUrl.searchParams.delete(key);
          }
        });
      }

      const searchParamsString = currentUrl.searchParams.toString();
      return `${currentUrl.pathname}${
        searchParamsString ? `?${searchParamsString}` : ''
      }`;
    },
    [isBrowser],
  );

  return updatedUrl;
}
