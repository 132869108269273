'use client';

import Button from '@/components/molecules/button';
import Dialog from '@/components/molecules/dialog';
import { useNotifications } from '@/components/providers/notification-provider';
import { useAppState } from '@/components/providers/state-provider';
import { deleteContent, isError } from '@/lib/helpers';
import { Content } from '@/types/sermons';

export default function ContentDeleteDialog({
  sermon,
  isOpen,
  setOpen,
  onDelete,
}: {
  sermon: Content;
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  onDelete?: () => void;
}) {
  const {
    state: { token },
  } = useAppState();

  const addNotification = useNotifications();

  return (
    <Dialog
      className='rounded border border-neutral-300'
      isOpen={isOpen}
      onBackdropClick={() => {
        setOpen(false);
      }}
    >
      <form
        onSubmit={async (e) => {
          e.preventDefault();

          const content = await deleteContent(sermon.id, token!);
          if (isError(content)) {
            addNotification({
              type: 'error',
              message: content.message,
            });
          } else {
            addNotification({
              type: 'success',
              message: content.success,
            });
            setOpen(false);
            if (onDelete) {
              onDelete();
            }
          }
        }}
      >
        <article>
          <header className='border-b border-neutral-300 bg-neutral-100 p-4'>
            <h1 className='font-bold'>
              Delete &quot;{sermon.contentTitle}&quot;
            </h1>
          </header>
          <section className='bg-white p-4'>
            <p>Are you sure you want to delete this content?</p>
          </section>
          <footer className='border-t border-neutral-300 bg-neutral-100 p-4 text-right'>
            <Button buttonType='red' type='submit' className='mr-4 h-9'>
              Delete
            </Button>
            <Button
              type='button'
              className='h-9'
              buttonType='flat-white'
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </Button>
          </footer>
        </article>
      </form>
    </Dialog>
  );
}
