'use client';

import NotificationPanel from '@/components/molecules/notification-panel';
import { Notification } from '@/types/sermons';
import { PropsWithChildren, createContext, useContext, useState } from 'react';
import { v4 } from 'uuid';

export const notificationContext = createContext<{
  addNotification: (notification: Notification) => Promise<void>;
}>({
  addNotification: async () => {},
});

export default function NotificationProvider({
  children,
}: PropsWithChildren<object>) {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const addNotification = async (notification: Notification) => {
    setNotifications((prevNotifications) => {
      const newNotifications = [...prevNotifications];
      if (!notification.uuid) {
        notification.uuid = v4();
      }
      newNotifications.push(notification);
      return newNotifications;
    });
  };

  const onDone = (uuid: string) => {
    setNotifications((prevNotifications) => {
      const newNotifications = [...prevNotifications];
      const index = newNotifications.findIndex(
        (notification) => notification.uuid === uuid,
      );

      if (index >= 0) {
        newNotifications.splice(index, 1);
        return newNotifications;
      } else {
        return prevNotifications;
      }
    });
  };

  return (
    <notificationContext.Provider value={{ addNotification }}>
      {children}
      <NotificationPanel notifications={notifications} onDone={onDone} />
    </notificationContext.Provider>
  );
}

export const useNotifications = () => {
  const { addNotification } = useContext(notificationContext);
  if (addNotification === undefined) {
    throw new Error('useNotifications must be used within a StateProvider');
  }
  return addNotification;
};
