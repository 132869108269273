import classNames from 'classnames';
import Image from 'next/image';

export default function Loading({ className }: { className?: string }) {
  return (
    <div className={classNames('text-center', className)}>
      <Image
        className='inline-block'
        src={'/img/ajax-loader.gif'}
        width={16}
        height={11}
        alt='Loading...'
        unoptimized
      />
    </div>
  );
}
