import { ErrorResponse, Session, User, UserPayload } from '@/types/sermons';
import Cookies from 'js-cookie';
import { decode, JwtPayload } from 'jsonwebtoken';

export const login = async (
  email: string,
  password: string,
  remember: boolean,
) => {
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/auth/login${
      remember ? '?remember' : ''
    }`,
    {
      method: 'POST',
      body: JSON.stringify({
        email,
        password,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      cache: 'no-store',
      next: {
        tags: ['all', 'users'],
      },
    },
  );
  const data = (await res.json()) as Session | ErrorResponse;

  return data;
};

export const getUser = async (token: string, cache = false) => {
  const res = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/users/me`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    cache: cache ? 'force-cache' : 'no-store',
    next: {
      tags: ['all', 'users'],
    },
  });
  if (typeof window !== 'undefined') {
    const userPayload = decode(token) as JwtPayload & UserPayload;
    Cookies.set('token', token, {
      expires: new Date(userPayload.exp * 1000),
      path: '/',
    });
    localStorage.setItem('token', token);
  }
  const data = (await res.json()) as Promise<User | ErrorResponse>;
  return data;
};
