'use client';

import { BibleResponse, Content } from '@/types/sermons';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useAppState } from '../providers/state-provider';
import BibleBrowser from './bible-browser';

export default function SermonBible({ sermon }: { sermon: Content }) {
  const {
    state: { searchParams, sermonPrepTab },
  } = useAppState();

  const pericopeCode = searchParams.get('pericopeCode');

  const pericope = sermon.pericopes?.filter(
    (p) => !pericopeCode || p.number === pericopeCode,
  )?.[0];

  const [bibleResponse, setBibleResponse] = useState<BibleResponse>();

  useEffect(() => {
    (async () => {
      if (pericope && pericope.number !== bibleResponse?.pericope.number) {
        const res = await fetch(
          `${
            process.env.NEXT_PUBLIC_API_BASE_URL
          }/bible?pericope=${encodeURIComponent(pericope.number)}`,
          {
            cache: 'force-cache',
            next: {
              tags: ['all', 'bible'],
            },
          },
        );
        const bibleResponse = (await res.json()) as BibleResponse;
        setBibleResponse(bibleResponse);
      }
    })();
  }, [pericope]);

  return (
    pericope &&
    bibleResponse && (
      <section
        className={classNames('border border-t-0 border-neutral-300 p-4', {
          hidden: sermonPrepTab !== 'bible',
        })}
      >
        <BibleBrowser bibleResponse={bibleResponse} typeOne='user' local />
      </section>
    )
  );
}
