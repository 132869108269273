'use client';

import Loading from '@/app/(dynamic)/loading';
import useUpdatedUrl from '@/hooks/use-update-url';
import {
  BIBLE_BOOKS_BY_NAME,
  BIBLE_TRANSLATION,
  BIBLE_TRANSLATIONS,
  fetchStudyTools,
  isBibleTranslation,
  verseReducer,
} from '@/lib/helpers';
import { BibleResponse, PericopeSuggestion, StudyTools } from '@/types/sermons';
import { default as classNames, default as cx } from 'classnames';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import { KeyboardEvent, useEffect, useRef, useState } from 'react';
import {
  BsCaretDownFill,
  BsCaretLeft,
  BsCaretLeftFill,
  BsCaretRight,
  BsCaretRightFill,
} from 'react-icons/bs';
import DropdownLink from '../atoms/dropdown-link';
import { useAppState } from '../providers/state-provider';
import TextAndTools from '../this-week/text-and-tools';
import Button from './button';
import SizedHeading from './sized-heading';
import TextInput from './text-input';

export default function BibleBrowser({
  typeOne: parentTypeOne,
  typeTwo: parentTypeTwo,
  bibleResponse: parentBibleResponse,
  local = false,
}: {
  typeOne?: BIBLE_TRANSLATION | 'user';
  typeTwo?: BIBLE_TRANSLATION | 'Parallel';
  bibleResponse?: BibleResponse;
  local?: boolean;
}) {
  const router = useRouter();

  const {
    state: {
      user,
      mySermons,
      searchParams,
      searchParamsReady,
      isBrowser,
      bibleBooks,
    },
    dispatch,
  } = useAppState();
  const { isMySermons, pericope } = mySermons;

  const [loading, setLoading] = useState(false);

  const [bibleDropdownOneOpen, setBibleDropdownOneOpen] = useState(false);
  const [bibleDropdownTwoOpen, setBibleDropdownTwoOpen] = useState(false);

  const bibleDropdownOneButtonRef = useRef<HTMLButtonElement>(null);
  const bibleDropdownTwoButtonRef = useRef<HTMLButtonElement>(null);

  const [typeOne, setTypeOne] = useState<BIBLE_TRANSLATION>(
    parentTypeOne
      ? parentTypeOne === 'user'
        ? user?.translation || 'niv'
        : 'niv'
      : 'niv',
  );
  const [typeTwo, setTypeTwo] = useState<BIBLE_TRANSLATION | 'Parallel'>(
    parentTypeTwo || 'Parallel',
  );
  const [bibleResponse, setBibleResponse] = useState<BibleResponse | undefined>(
    parentBibleResponse,
  );

  const [studyTools, setStudyTools] = useState<StudyTools>();

  const [contentLoading, setContentLoading] = useState(false);
  const [initialLoadDone, setInitialLoadDone] = useState(local);

  const qPericope = searchParams.get('pericope');
  const qTypeOne = searchParams.get('typeOne');
  const qTypeTwo = searchParams.get('typeTwo');
  const qChapter = searchParams.get('chapter');
  const qBook = searchParams.get('book');
  useEffect(() => {
    if (!local) {
      if (qPericope && qPericope !== bibleResponse?.pericope.number) {
        setContentLoading(true);
        (async () => {
          const response = await fetchPericope(qPericope);
          const bookName = response.pericope.book.toLowerCase();
          const chapter = response.pericope.chapter;
          const book =
            [
              ...(bibleBooks?.oldTestament || []),
              ...(bibleBooks?.newTestament || []),
            ].findIndex(
              (b) =>
                bookName === b.toLowerCase() ||
                (b === 'Psalm' && bookName === 'psalm'),
            ) + 1;
          if (qBook !== `${book}` || qChapter !== `${chapter}`) {
            router.replace(
              updatedUrl({
                book: `${book}`,
                chapter: `${chapter}`,
              }),
            );
          }
          setBibleResponse(response);
          setContentLoading(false);
          if (!initialLoadDone) {
            setInitialLoadDone(true);
          }
        })().catch(console.error);
      } else if (searchParamsReady && !initialLoadDone) {
        setInitialLoadDone(true);
      }
      if (isBibleTranslation(qTypeOne)) {
        setTypeOne(qTypeOne);
      }
      if (isBibleTranslation(qTypeTwo) || qTypeTwo === 'Parallel') {
        setTypeTwo(qTypeTwo);
      } else {
        setTypeTwo('Parallel');
      }
    }
  }, [local, qPericope, qTypeOne, qTypeTwo]);

  useEffect(() => {
    if (
      parentBibleResponse &&
      parentBibleResponse.pericope.number !== bibleResponse?.pericope.number &&
      (!qPericope || qPericope === parentBibleResponse.pericope.number)
    ) {
      (async () => {
        if (isMySermons && parentBibleResponse.pericope.number) {
          (async () => {
            const newStudyTools = await fetchStudyTools(
              parentBibleResponse.pericope.number,
            );
            setStudyTools(newStudyTools);
          })().catch(console.error);
        }
        setBibleResponse(parentBibleResponse);
        if (pericope && pericope !== parentBibleResponse.pericope.number) {
          setScriptureReference({
            code: pericope,
            reference: '',
          });
        } else if (!scriptureReference.code) {
          setScriptureReference({
            code: parentBibleResponse.pericope.number || 'GEN001',
            reference: parentBibleResponse.pericope.fullReference || '',
          });
        }
      })();
    }
  }, [parentBibleResponse]);

  useEffect(() => {
    setTypeOne(
      parentTypeOne
        ? parentTypeOne === 'user'
          ? user?.translation || 'niv'
          : 'niv'
        : 'niv',
    );
    setTypeTwo(parentTypeTwo || 'Parallel');
  }, [parentTypeOne, parentTypeTwo]);

  useEffect(() => {
    const eventHandler = (e: MouseEvent) => {
      if (!bibleDropdownOneButtonRef.current?.contains(e.target as Node)) {
        setBibleDropdownOneOpen(false);
      }
      if (!bibleDropdownTwoButtonRef.current?.contains(e.target as Node)) {
        setBibleDropdownTwoOpen(false);
      }
    };
    document.addEventListener('click', eventHandler);

    return () => {
      document.removeEventListener('click', eventHandler);
    };
  }, []);

  const pathname = usePathname();

  const paragraphsOne: JSX.Element[][] =
    bibleResponse?.verses?.reduce(
      verseReducer(typeOne),
      [] as JSX.Element[][],
    ) || [];

  const paragraphsTwo: JSX.Element[][] =
    (BIBLE_TRANSLATIONS.includes(typeTwo as BIBLE_TRANSLATION)
      ? bibleResponse?.verses?.reduce(
          verseReducer(typeTwo as BIBLE_TRANSLATION),
          [] as JSX.Element[][],
        )
      : []) || [];

  const paragraphs = paragraphsOne.flatMap((p, index) =>
    BIBLE_TRANSLATIONS.includes(typeTwo as BIBLE_TRANSLATION)
      ? [p, paragraphsTwo[index]]
      : [p],
  );

  const fetchPericope = async (code: string) => {
    const res = await fetch(
      `${
        process.env.NEXT_PUBLIC_API_BASE_URL
      }/bible?pericope=${encodeURIComponent(code)}`,
      {
        cache: 'force-cache',
        next: {
          tags: ['all', 'bible'],
        },
      },
    );
    const bibleResponse = (await res.json()) as BibleResponse;

    setScriptureReference({
      code: bibleResponse.pericope.number,
      reference: bibleResponse.pericope.fullReference,
    });

    if (isMySermons) {
      if (code !== pericope) {
        dispatch({
          type: 'mySermons',
          payload: { ...mySermons, pericope: code },
        });
      }
      (async () => {
        const newStudyTools = await fetchStudyTools(
          bibleResponse?.pericope.number,
        );
        setStudyTools(newStudyTools);
      })().catch(console.error);
    }

    return bibleResponse;
  };

  const [scriptureReference, setScriptureReference] =
    useState<PericopeSuggestion>({
      code: bibleResponse?.pericope.number || '',
      reference: bibleResponse?.pericope.fullReference || '',
    });
  const [suggestions, setSuggestions] = useState<PericopeSuggestion[]>([]);
  const [selectedSuggestion, setSelectedSuggestion] =
    useState<PericopeSuggestion>();
  const suggestionsDropdown = useRef<HTMLUListElement>(null);

  const updateSuggestions = async (term: string) => {
    const res = await fetch(
      `${
        process.env.NEXT_PUBLIC_API_BASE_URL
      }/bible/pericope-suggestions?query=${encodeURIComponent(term)}`,
      {
        cache: 'force-cache',
        next: {
          tags: ['all', 'bible'],
        },
      },
    );
    const suggestionsData: PericopeSuggestion[] = await res.json();

    const suggestion = suggestionsData.find(
      (s) => s.reference.toLowerCase() === term.toLowerCase(),
    );
    if (suggestion) {
      setSelectedSuggestion(suggestion);
    }

    setSuggestions(suggestionsData);
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    let newSelectedSuggestion: PericopeSuggestion | undefined = undefined;
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      const offset = e.key === 'ArrowDown' ? 1 : -1;
      e.preventDefault();
      if (suggestions.length === 0) {
        updateSuggestions(scriptureReference?.reference || '');
        return;
      }
      if (!selectedSuggestion) {
        newSelectedSuggestion = suggestions[0];
      } else {
        const index = suggestions.findIndex(
          (s) => s.code === selectedSuggestion.code,
        );
        if (index + offset < suggestions.length && index + offset >= 0) {
          newSelectedSuggestion = suggestions[index + offset];
        }
      }
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (selectedSuggestion) {
        setScriptureReference({
          code: selectedSuggestion.code,
          reference: selectedSuggestion.reference,
        });
        setSuggestions([]);
      }
    }
    if (
      newSelectedSuggestion &&
      selectedSuggestion?.code !== newSelectedSuggestion.code
    ) {
      setSelectedSuggestion(newSelectedSuggestion);
      if (suggestionsDropdown.current) {
        const liElem = [
          ...suggestionsDropdown.current.querySelectorAll('li'),
        ].find((e) => e.textContent === newSelectedSuggestion!.reference);
        liElem?.scrollIntoView?.({ block: 'nearest', inline: 'start' });
      }
    }
  };

  useEffect(() => {
    const eventHandler = (e: MouseEvent) => {
      const elem = document.getElementById(
        'scriptureReference',
      ) as HTMLInputElement | null;
      if (!elem?.contains(e.target as Node)) {
        setSuggestions([]);
      }
    };

    document.addEventListener('click', eventHandler);

    return () => {
      document.removeEventListener('click', eventHandler);
    };
  }, []);

  const updatedUrl = useUpdatedUrl();

  useEffect(() => {
    let code = scriptureReference.code;
    if (!code && pericope) {
      code = pericope;
    }
    if (code !== bibleResponse?.pericope.number) {
      if (local) {
        (async () => {
          const response = await fetchPericope(code || 'GEN001');
          setBibleResponse(response);
        })().catch(console.error);
      } else {
        router.push(updatedUrl({ pericope: code }));
      }
    }
  }, [local, scriptureReference]);

  useEffect(() => {
    if (isMySermons) {
      if (scriptureReference.code && scriptureReference.code !== pericope) {
        setScriptureReference((prevScriptureReference) => {
          if (pericope !== prevScriptureReference.code) {
            return {
              code: pericope,
              reference: '',
            };
          } else {
            return prevScriptureReference;
          }
        });
      }
    }
  }, [pericope, isMySermons, scriptureReference]);

  return (
    <section className='relative'>
      {(!isBrowser || !searchParamsReady || !initialLoadDone) && (
        <Loading className='absolute inset-0 z-10 bg-white' />
      )}
      <div className='grid grid-cols-1 gap-4 pt-[5px]'>
        <div className='flex items-center justify-center'>
          {bibleResponse?.prevPericope && parentTypeOne && !local && (
            <Link
              className='select-none'
              href={`${pathname}?pericope=${
                bibleResponse?.prevPericope.number
              }&chapter=${bibleResponse?.prevPericope.chapter}&book=${
                BIBLE_BOOKS_BY_NAME[bibleResponse?.prevPericope.book]
              }&typeOne=${typeOne}&typeTwo=${typeTwo}`}
            >
              <BsCaretLeftFill className='relative top-[-2px] inline text-sermons-dark' />
            </Link>
          )}
          {bibleResponse?.prevPericope && (!parentTypeOne || local) && (
            <>
              {loading && <Loading className='select-none' />}
              {!loading && (
                <BsCaretLeftFill
                  className='relative top-[-2px] inline cursor-pointer select-none text-sermons-dark'
                  onClick={async () => {
                    setLoading(true);
                    const response = await fetchPericope(
                      bibleResponse?.prevPericope!.number,
                    );
                    setBibleResponse(response);
                    setLoading(false);
                  }}
                />
              )}
            </>
          )}
          {!bibleResponse?.prevPericope && (
            <BsCaretLeft className='relative top-[-2px] inline select-none text-sermons-dark' />
          )}
          <div className='relative'>
            <TextInput
              autoComplete='off'
              className='w-full'
              id='scriptureReference'
              label='Scripture Reference'
              value={scriptureReference?.reference}
              onFocus={(e) => {
                updateSuggestions(scriptureReference?.reference || '');
                e.target.select();
              }}
              onChange={(e) => {
                setScriptureReference({
                  code: '',
                  reference: e.target.value,
                });
                updateSuggestions(e.target.value);
              }}
              onKeyDown={onKeyDown}
            />
            {suggestions && suggestions.length > 0 && (
              <ul
                className='absolute left-0 top-9 z-50 inline-block max-h-[calc(100vh_-_128px)] overflow-auto border border-neutral-300 bg-white'
                ref={suggestionsDropdown}
              >
                {suggestions?.map((suggestion) => (
                  <li
                    key={suggestion.reference}
                    className={classNames(
                      'cursor-pointer px-2 hover:bg-sermons-light hover:text-white',
                      {
                        'bg-sermons-dark text-white':
                          suggestion === selectedSuggestion,
                      },
                    )}
                    onClick={() => {
                      setScriptureReference(suggestion);
                      setSuggestions([]);
                    }}
                  >
                    {suggestion.reference}
                  </li>
                ))}
              </ul>
            )}
          </div>
          {bibleResponse?.nextPericope && parentTypeOne && !local && (
            <Link
              className='select-none'
              href={`${pathname}?pericope=${
                bibleResponse?.nextPericope.number
              }&chapter=${bibleResponse?.nextPericope.chapter}&book=${
                BIBLE_BOOKS_BY_NAME[bibleResponse?.nextPericope.book]
              }&typeOne=${typeOne}&typeTwo=${typeTwo}`}
            >
              <BsCaretRightFill className='relative top-[-2px] inline text-sermons-dark' />
            </Link>
          )}
          {bibleResponse?.nextPericope && (!parentTypeOne || local) && (
            <>
              {loading && <Loading className='select-none' />}
              {!loading && (
                <BsCaretRightFill
                  className='relative top-[-2px] inline cursor-pointer select-none text-sermons-dark'
                  onClick={async () => {
                    setLoading(true);
                    const response = await fetchPericope(
                      bibleResponse?.nextPericope!.number,
                    );
                    setBibleResponse(response);
                    setLoading(false);
                  }}
                />
              )}
            </>
          )}
          {!bibleResponse?.nextPericope && (
            <BsCaretRight className='relative top-[-2px] inline select-none text-sermons-dark' />
          )}
        </div>
        <div className='flex select-none items-center justify-center'>
          <Button
            type='button'
            className='relative mx-4 flex items-center justify-center overflow-visible'
            onClick={() => setBibleDropdownOneOpen(!bibleDropdownOneOpen)}
            ref={bibleDropdownOneButtonRef}
          >
            {typeOne.toUpperCase()} <BsCaretDownFill className='ml-1' />
            <nav
              className={cx(
                'absolute left-0 top-8 z-50 border border-neutral-300 bg-white text-sm',
                {
                  hidden: !bibleDropdownOneOpen,
                },
              )}
            >
              <ul className='grid grid-cols-1'>
                {BIBLE_TRANSLATIONS.map((translation) => (
                  <li
                    key={translation}
                    className='cursor-pointer text-sermons-dark hover:bg-neutral-100 hover:underline'
                  >
                    {parentTypeOne && !local && (
                      <DropdownLink
                        linkText={translation}
                        onClick={() => setTypeOne(translation)}
                        paramSetup={(searchParams) => {
                          searchParams.set('typeOne', translation);
                          searchParams.set('typeTwo', typeTwo);
                          return searchParams;
                        }}
                      />
                    )}
                    {(!parentTypeOne || local) && (
                      <span
                        className='inline-block w-full px-4 py-1'
                        onClick={() => setTypeOne(translation)}
                      >
                        {translation.toUpperCase()}
                      </span>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
          </Button>
          {!isMySermons && (
            <Button
              type='button'
              className='relative mr-4 flex items-center justify-center overflow-visible'
              onClick={() => setBibleDropdownTwoOpen(!bibleDropdownTwoOpen)}
              ref={bibleDropdownTwoButtonRef}
            >
              {typeTwo === 'Parallel' ? typeTwo : typeTwo.toUpperCase()}{' '}
              <BsCaretDownFill className='ml-1' />
              <nav
                className={cx(
                  'absolute left-0 top-8 z-50 border border-neutral-300 bg-white text-sm',
                  {
                    hidden: !bibleDropdownTwoOpen,
                  },
                )}
              >
                <ul className='grid grid-cols-1'>
                  {BIBLE_TRANSLATIONS.map((translation) => (
                    <li
                      key={translation}
                      className='cursor-pointer text-sermons-dark hover:bg-neutral-100 hover:underline'
                    >
                      {typeTwo === translation && !local && (
                        <DropdownLink
                          linkText={translation}
                          paramSetup={(searchParams) => {
                            searchParams.set('typeOne', typeOne);
                            searchParams.delete('typeTwo');
                            return searchParams;
                          }}
                        />
                      )}
                      {typeTwo === translation && local && (
                        <span
                          className='inline-block w-full px-4 py-1'
                          onClick={() => setTypeTwo('Parallel')}
                        >
                          {translation.toUpperCase()}
                        </span>
                      )}
                      {typeTwo !== translation && !local && (
                        <DropdownLink
                          linkText={translation}
                          paramSetup={(searchParams) => {
                            searchParams.set('typeOne', typeOne);
                            searchParams.set('typeTwo', translation);
                            return searchParams;
                          }}
                        />
                      )}
                      {typeTwo !== translation && local && (
                        <span
                          className='inline-block w-full px-4 py-1'
                          onClick={() => setTypeTwo(translation)}
                        >
                          {translation.toUpperCase()}
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              </nav>
            </Button>
          )}
        </div>
      </div>
      {(loading || contentLoading) && <Loading />}
      {!loading && !contentLoading && (
        <section>
          <article>
            <header>
              <h2
                className={classNames('my-4', {
                  'text-2xl': !isMySermons,
                  'text-center font-bold': isMySermons,
                })}
              >
                <SizedHeading
                  className='max-w-full'
                  headings={[
                    <>
                      {bibleResponse?.pericope.heading}
                      {!isMySermons && (
                        <> ({bibleResponse?.pericope.fullReference})</>
                      )}
                    </>,
                    <>
                      {bibleResponse?.pericope.shortHeading}
                      {!isMySermons && (
                        <> ({bibleResponse?.pericope.fullReference})</>
                      )}
                    </>,
                  ]}
                />
              </h2>
            </header>
            <section
              className={cx('grid gap-4', {
                'grid-cols-2': BIBLE_TRANSLATIONS.includes(
                  typeTwo as BIBLE_TRANSLATION,
                ),
              })}
            >
              {paragraphs.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
            </section>
          </article>
        </section>
      )}
      {isMySermons && studyTools && !loading && (
        <TextAndTools studyTools={studyTools} isMySermons />
      )}
    </section>
  );
}
