'use client';

import Loading from '@/app/(dynamic)/loading';
import '@/app/(dynamic)/user/signup/loading.css';
import Dialog from '@/components/molecules/dialog';
import { createContent, isError } from '@/lib/helpers';
import { useState } from 'react';
import { useNotifications } from '../providers/notification-provider';
import { useAppState } from '../providers/state-provider';
import Button from './button';
import MyEditor from './editor';
import Select from './select';
import TextInput from './text-input';

export default function CreateQuoteDialog({
  isOpen,
  setOpen,
}: {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
}) {
  const addNotification = useNotifications();
  const {
    state: { token },
  } = useAppState();

  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState('');
  const [author, setAuthor] = useState('');
  const [priority, setPriority] = useState<number>();

  return (
    <Dialog
      className='overflow-hidden rounded border border-neutral-300 bg-white p-4 text-base'
      isOpen={isOpen}
      onBackdropClick={() => setOpen(false)}
    >
      {loading && <Loading />}
      {!loading && (
        <form
          className='grid grid-cols-12 gap-4'
          onSubmit={async (e) => {
            e.preventDefault();

            if (!token) {
              return;
            }

            setLoading(true);

            const result = await createContent(
              {
                author,
                content,
                contentType: '39',
                contentTitle: '',
                pericopes: [],
                priority,
              },
              token,
            );

            if (isError(result)) {
              addNotification({
                type: 'error',
                message: result.message,
              });
            } else {
              addNotification({
                type: 'success',
                message: result.success,
              });
              setContent('');
              setAuthor('');
              setOpen(false);
            }

            setLoading(false);
          }}
        >
          <TextInput
            className='w-full'
            containerClassName='col-span-11'
            id='author'
            label='Author'
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
          />
          <Select
            className='w-full'
            label='Priority'
            onChange={(e) =>
              e.target.value === ''
                ? setPriority(undefined)
                : setPriority(+e.target.value)
            }
            value={priority === undefined ? '' : priority}
          >
            <option value=''>-</option>
            {Array(5)
              .fill(null)
              .map((_, i) => (
                <option key={i} value={i}>
                  {i}
                </option>
              ))}
          </Select>
          <MyEditor
            data={content}
            onChange={(_, editor) => {
              setContent(editor.getData());
            }}
            onReady={(editor) => {
              const elem = editor.ui.getEditableElement();
              const parentElement = elem?.parentElement;
              if (parentElement) {
                parentElement.classList.add('sermon-content');
              }
            }}
          />
          <div className='flex justify-end gap-4'>
            <Button buttonType='flat-white' onClick={() => setOpen(false)}>
              Close
            </Button>
            <Button type='submit'>Save</Button>
          </div>
        </form>
      )}
    </Dialog>
  );
}
