'use client';

import classNames from 'classnames';
import { HTMLAttributes, ReactNode, useEffect, useRef, useState } from 'react';

export default function SizedHeading({
  headings,
  className,
  ...props
}: {
  headings: ReactNode[];
} & HTMLAttributes<HTMLDivElement>) {
  const ref = useRef<HTMLDivElement>(null);
  const [headingIndex, setHeadingIndex] = useState(0);

  const chooseIndex = () => {
    if (
      ref.current &&
      ref.current.scrollWidth > ref.current.clientWidth &&
      headingIndex < headings.length - 1
    ) {
      setHeadingIndex((headingIndex) =>
        headingIndex + 1 < headings.length
          ? headingIndex + 1
          : headings.length - 1,
      );
    }
  };

  useEffect(() => {
    chooseIndex();
  }, [
    ref.current?.scrollWidth,
    ref.current?.clientWidth,
    headingIndex,
    headings,
    chooseIndex,
  ]);

  const [requestingFrame, setRequestingFrame] = useState(false);

  useEffect(() => {
    const handler = () => {
      if (!requestingFrame) {
        setRequestingFrame(true);
        requestAnimationFrame(() => {
          setHeadingIndex(0);
          setTimeout(() => {
            chooseIndex();
            setRequestingFrame(false);
          });
        });
      }
    };
    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  return (
    <div
      ref={ref}
      className={classNames(
        'inline-block w-full overflow-hidden',
        {
          'whitespace-nowrap': headingIndex < headings.length - 1,
        },
        className,
      )}
      {...props}
    >
      {headings[headingIndex]}
    </div>
  );
}
