'use client';

import cx from 'classnames';
import { useRouter } from 'next/navigation';
import { DetailedHTMLProps, HTMLAttributes, PropsWithChildren, useCallback, useEffect, useRef } from 'react';

export default function Dialog({
  children,
  className,
  isOpen,
  onBackdropClick,
  navigateBack,
}: PropsWithChildren<{
  isOpen: boolean;
  onBackdropClick?: () => void;
  navigateBack?: boolean;
} & DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>>) {
  const router = useRouter();

  const sectionRef = useRef<HTMLElement>(null);

  const onKeypress = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      if (navigateBack) {
        router.back();
      }
      if (onBackdropClick) {
        onBackdropClick();
      }
    }
  }, [router]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [isOpen]);

  useEffect(() => {
    document.addEventListener('keyup', onKeypress);

    return () => {
      document.body.classList.remove('modal-open');

      document.removeEventListener('keyup', onKeypress);
    };
  }, [router])

  if (isOpen) {
    return (
      <>
        <div className='fixed inset-0 z-50 overflow-auto bg-[rgba(0,_0,_0,_0.4)]' onClick={(e) => {
          if (sectionRef.current?.contains(e.target as Node)) {
            return;
          }
          if (navigateBack) {
            router.back();
          }
          if (onBackdropClick) {
            onBackdropClick();
          }
        }}>
          <section ref={sectionRef} className={cx('mb-4 mt-[72px] mx-auto max-w-4xl shadow-[0px_3px_6px_0px_rgb(0_0_0_/_60%)]', className)}>
            {children}
          </section>
        </div>
      </>
    );
  } else {
    return null;
  }
}
