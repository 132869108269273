'use client';

import type { Editor as BaseEditor } from '@ckeditor/ckeditor5-core';
import type {
  CKEditor,
  default as CKReactImport,
} from '@ckeditor/ckeditor5-react';
import { FileLoader } from '@ckeditor/ckeditor5-upload';
import type Editor from 'ckeditor5-custom-build/build/ckeditor';
import { useEffect, useState } from 'react';

function uploadPlugin(editor: BaseEditor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (
    loader: FileLoader
  ) => {
    return {
      upload: async () => {
        const file = await loader.file;
        if (file) {
          const dataUrl = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
          });
          return {
            default: dataUrl,
          };
        }
        throw new Error('No file provided.');
      },
    };
  };
}

export default function MyEditor(
  props: Partial<React.ComponentProps<typeof CKEditor>>
) {
  const [editor, setEditor] = useState<{ default: typeof Editor }>();
  const [CK, setCK] = useState<typeof CKReactImport>();

  useEffect(() => {
    (async () => {
      setEditor(await import('ckeditor5-custom-build/build/ckeditor'));
      setCK(await import('@ckeditor/ckeditor5-react'));
    })().catch(console.error);
  }, []);

  return (
    <>
      {CK && editor && (
        <CK.CKEditor
          config={{
            extraPlugins: [uploadPlugin],
          }}
          editor={editor.default}
          {...props}
        />
      )}
      {(!CK || !editor) && 'Loading...'}
    </>
  );
}
