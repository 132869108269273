'use client';

import cx from 'classnames';
import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';
import { DetailedHTMLProps, HTMLAttributes, useEffect, useState } from 'react';

export default function IllustrationsTabs({
  illustrationsTotal,
  quotesTotal,
  humorTotal,
  className,
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> & {
  illustrationsTotal: number;
  quotesTotal: number;
  humorTotal: number;
}) {
  const [tab, setTab] = useState<'Illustrations' | 'Quotes' | 'Humor'>('Illustrations');

  const pathname = usePathname();
  const searchParams = useSearchParams();

  const queryStrings: string[] = [];
  for (const [key, value] of searchParams.entries()) {
    if (!['tab', 'page', 'gsPage', 'epPage', 'otPage', 'psPage'].includes(key)) {
      queryStrings.push(`${key}=${encodeURIComponent(value)}`);
    }
  }
  let queryString = `?${queryStrings.join('&')}`;
  if (queryString !== '?') {
    queryString += '&';
  }

  const qTab = searchParams.get('tab');
  const qSermonFilters = searchParams.get('sermon_filters');
  useEffect(() => {
    if (['Illustrations', 'Quotes', 'Humor'].includes(qTab as string)) {
      setTab(qTab as 'Illustrations' | 'Quotes' | 'Humor' || 'Illustrations');
    } else if ([null, 'Illustrations', 'Quotes', 'Humor'].includes(qSermonFilters)) {
      setTab(qSermonFilters as 'Illustrations' | 'Quotes' | 'Humor' || 'Illustrations');
    }
  }, [qTab]);

  return (
    <nav className={cx('w-full overflow-auto bg-sermons-dark text-white font-bold h-10', className)} {...props}>
      <div className='grid min-w-[672px] grid-cols-5 whitespace-nowrap'>
        <div className={cx('flex h-10 items-center justify-center border-b-4 cursor-pointer', {
          'border-b-sermons-highlight': tab === 'Illustrations',
          'border-b-sermons-dark': tab !== 'Illustrations',
        })}>
          <Link className='flex h-10 w-full items-center justify-center' href={`${pathname}${queryString}tab=Illustrations`}>
            Illustrations <span className='relative -top-1 left-1 align-super text-xs'>{illustrationsTotal}</span>
          </Link>
        </div>
        <div className={cx('flex h-10 items-center justify-center border-b-4 cursor-pointer', {
          'border-b-sermons-highlight': tab === 'Quotes',
          'border-b-sermons-dark': tab !== 'Quotes',
        })}>
          <Link className='flex h-10 w-full items-center justify-center' href={`${pathname}${queryString}tab=Quotes`}>
            Quotes <span className='relative -top-1 left-1 align-super text-xs'>{quotesTotal}</span>
          </Link>
        </div>
        <div className={cx('flex h-10 items-center justify-center border-b-4 cursor-pointer', {
          'border-b-sermons-highlight': tab === 'Humor',
          'border-b-sermons-dark': tab !== 'Humor',
        })}>
          <Link className='flex h-10 w-full items-center justify-center' href={`${pathname}${queryString}tab=Humor`}>
            Humor <span className='relative -top-1 left-1 align-super text-xs'>{humorTotal}</span>
          </Link>
        </div>
      </div>
    </nav>
  )
}
