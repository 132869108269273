'use client';

import '@/app/(dynamic)/user/signup/loading.css';
import { useState } from 'react';
import { BsPlus } from 'react-icons/bs';
import Button from './button';
import CreateQuoteDialog from './create-quote-dialog';


export default function AddQuote() {
  const [createQuoteOpen, setCreateQuoteOpen] = useState(false);

  return (
    <>
      <Button
        className='flex size-6 items-center justify-center !p-0'
        onClick={() => setCreateQuoteOpen(true)}
      >
        <BsPlus className='text-xl' strokeWidth={1.25} />
      </Button>
      <CreateQuoteDialog isOpen={createQuoteOpen} setOpen={setCreateQuoteOpen} />
    </>
  );
}
