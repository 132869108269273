'use client';

import { default as classNames, default as cx } from 'classnames';
import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';

const TAB_NAMES = [
  'Gospel',
  'Epistle',
  'Old Testament',
  'Psalm',
  'Keyword',
] as const;
type TAB_NAME = typeof TAB_NAMES[number];

export default function LectionaryTabs({
  hideTotals,
  otTotal,
  psTotal,
  epTotal,
  gsTotal,
  kwTotal,
}: {
  hideTotals?: boolean;
  otTotal: number;
  psTotal: number;
  epTotal: number;
  gsTotal: number;
  kwTotal?: number;
}) {
  const [tab, setTab] = useState<TAB_NAME>('Gospel');

  const pathname = usePathname();
  const searchParams = useSearchParams();

  const queryStrings: string[] = [];
  for (const [key, value] of searchParams.entries()) {
    if (key !== 'tab2') {
      queryStrings.push(`${key}=${encodeURIComponent(value)}`);
    }
  }
  let queryString = `?${queryStrings.join('&')}`;
  if (queryString !== '?') {
    queryString += '&';
  }

  const qTab = searchParams.get('tab2');
  useEffect(() => {
    if (TAB_NAMES.includes(qTab as TAB_NAME)) {
      setTab(qTab as TAB_NAME || 'Gospel');
    }
  }, [qTab]);

  return (
    <nav className='h-10 w-full overflow-auto border border-b-0 border-neutral-300 font-bold'>
      <div className={classNames('grid grid-cols-10 whitespace-nowrap min-w-[672px] h-full', {
        'grid-cols-10': kwTotal === undefined,
        'grid-cols-12': kwTotal !== undefined,
      })}>
        <div></div>
        <div className={cx('flex col-span-2 items-center justify-center cursor-pointer', {
          'text-sermons-light underline': tab === 'Gospel',
          'text-sermons-dark': tab !== 'Gospel',
        })}>
          <Link className='flex w-full items-center justify-center' href={`${pathname}${queryString}tab2=Gospel`}>
            Gospel{!hideTotals && <sup className="pl-1">{gsTotal}</sup>}
          </Link>
        </div>
        <div className={cx('flex col-span-2 items-center justify-center cursor-pointer', {
          'text-sermons-light underline': tab === 'Epistle',
          'text-sermons-dark': tab !== 'Epistle',
        })}>
          <Link className='flex w-full items-center justify-center' href={`${pathname}${queryString}tab2=Epistle`}>
            Epistle{!hideTotals && <sup className="pl-1">{epTotal}</sup>}
          </Link>
        </div>
        <div className={cx('flex col-span-2 items-center justify-center cursor-pointer', {
          'text-sermons-light underline': tab === 'Old Testament',
          'text-sermons-dark': tab !== 'Old Testament',
        })}>
          <Link className='flex w-full items-center justify-center' href={`${pathname}${queryString}tab2=Old Testament`}>
            Old Testament{!hideTotals && <sup className="pl-1">{otTotal}</sup>}
          </Link>
        </div>
        <div className={cx('flex col-span-2 items-center justify-center cursor-pointer', {
          'text-sermons-light underline': tab === 'Psalm',
          'text-sermons-dark': tab !== 'Psalm',
        })}>
          <Link className='flex w-full items-center justify-center' href={`${pathname}${queryString}tab2=Psalm`}>
            Psalm{!hideTotals && <sup className="pl-1">{psTotal}</sup>}
          </Link>
        </div>
        {kwTotal !== undefined &&
          <div className={cx('flex col-span-2 items-center justify-center cursor-pointer', {
            'text-sermons-light underline': tab === 'Keyword',
            'text-sermons-dark': tab !== 'Keyword',
          })}>
            <Link className='flex w-full items-center justify-center' href={`${pathname}${queryString}tab2=Keyword`}>
              Keyword{!hideTotals && <sup className="pl-1">{kwTotal}</sup>}
            </Link>
          </div>
        }
        <div></div>
      </div>
    </nav>
  )
}
