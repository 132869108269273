'use client';

import { Notification } from '@/types/sermons';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { BsXCircle } from 'react-icons/bs';

export default function Alert({
  notification,
  onDone,
}: {
  notification: Notification,
  onDone: (uuid: string) => void,
}) {
  const [timeoutRef, setTimeoutRef] = useState<NodeJS.Timeout>();
  useEffect(() => {
    setTimeoutRef(setTimeout(() => {
      onDone(notification.uuid!);
    }, 5000));

    return () => {
      clearTimeout(timeoutRef);
    }
  }, []);

  return (
    <div className={classNames('rounded p-4 w-full', {
      'bg-red-700 text-white': notification.type === 'error',
      'bg-green-700 text-white': notification.type === 'success',
    })}>
      <div className='inline-block w-[calc(100%_-_1rem)] whitespace-pre-wrap'>
        {Array.isArray(notification.message) ? notification.message.join('\n') : notification.message}
      </div>
      <div className="inline-flex w-4 items-center text-right text-2xl">
        <button onClick={() => {
          clearTimeout(timeoutRef);
          onDone(notification.uuid!);
        }}>
          <BsXCircle />
        </button>
      </div>
    </div>
  );
}
