import classNames from 'classnames';
import Link from 'next/link';

export default function SynAntTools({
  term,
  type,
  semantic,
  synonyms,
  antonyms,
}: {
  term: string;
  type: string;
  semantic?: string;
  synonyms?: string[];
  antonyms?: string[];
}) {
  return (
    <>
      {((!!synonyms && synonyms.length > 0) ||
        (!!antonyms && antonyms.length > 0)) && (
        <section className='mb-2 border border-neutral-300 bg-white px-4 py-2'>
          {!!synonyms && synonyms.length > 0 && (
            <div className='h-6 overflow-hidden pr-4'>
              Synonyms:{' '}
              {synonyms.map((synonym, index) => (
                <span
                  key={synonym}
                  className={classNames(
                    'relative z-10 inline-block whitespace-pre bg-white after:absolute',
                    {
                      'after:content-none': index === synonyms!.length - 1,
                      'after:content-["…"]': index < synonyms!.length - 1,
                    },
                  )}
                >
                  <span>
                    {index > 0 && <span>, </span>}
                    <span className='cursor-pointer'>
                      <Link
                        className={classNames(
                          'text-sermons-dark hover:underline',
                          {
                            'font-bold': semantic === synonym,
                          },
                        )}
                        href={`/search/keyword?term=${encodeURIComponent(
                          term,
                        )}&semantic=${encodeURIComponent(
                          synonym,
                        )}&type=${encodeURIComponent(type)}`}
                        prefetch={false}
                      >
                        {synonym}
                      </Link>
                    </span>
                  </span>
                </span>
              ))}
            </div>
          )}
          {!!antonyms && antonyms.length > 0 && (
            <div className='h-6 overflow-hidden pr-4'>
              Antonyms:{' '}
              {antonyms.map((antonym, index) => (
                <span
                  key={antonym}
                  className={classNames(
                    'relative z-10 inline-block whitespace-pre bg-white after:absolute',
                    {
                      'after:content-none': index === antonyms!.length - 1,
                      'after:content-["…"]': index < antonyms!.length - 1,
                    },
                  )}
                >
                  <span>
                    {index > 0 && <span>, </span>}
                    <span>
                      <Link
                        className={classNames(
                          'text-sermons-dark hover:underline',
                          {
                            'font-bold': semantic === antonym,
                          },
                        )}
                        href={`/search/keyword?term=${encodeURIComponent(
                          term,
                        )}&semantic=${encodeURIComponent(
                          antonym,
                        )}&type=${encodeURIComponent(type)}`}
                        prefetch={false}
                      >
                        {antonym}
                      </Link>
                    </span>
                  </span>
                </span>
              ))}
            </div>
          )}
        </section>
      )}
    </>
  );
}
