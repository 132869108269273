'use client';

import { Notification } from '@/types/sermons';
import Alert from '../atoms/alert';

export default function NotificationPanel({
  notifications,
  onDone,
}: {
  notifications: Notification[],
  onDone: (uuid: string) => void,
}) {
  return (
    <section className='fixed bottom-4 left-1/2 z-50 grid w-screen max-w-[min(40rem,100vw_-_2rem)] -translate-x-1/2 grid-cols-1 gap-4'>
      {notifications.map((notification) => (
        <Alert key={notification.uuid} notification={notification} onDone={onDone} />
      ))}
    </section>
  );
}
