'use client';

import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useAppState } from '../providers/state-provider';

export default function LoggedOutMessage() {
  const {
    state: { user, isLoading, searchParams },
  } = useAppState();

  const pathname = usePathname();

  const url = new URL(`https://null${pathname}?${searchParams}`);

  return (
    <>
      {user && !isLoading && (
        <section className='my-4 flex items-center justify-center'>
          <div className='rounded-md border-4 border-sermons-dark bg-sky-50 p-4 text-center'>
            <div>You do not have access to this content.</div>
            <div>
              If you would like to access this content, please update your
              subscriptions in{' '}
              <Link
                className='font-bold text-sermons-dark hover:underline'
                href='/my-account'
                onClick={() =>
                  localStorage.setItem(
                    'renewRedirect',
                    `${url.pathname}${url.search}`,
                  )
                }
              >
                My Account
              </Link>
              .
            </div>
          </div>
        </section>
      )}
      {!user && !isLoading && (
        <section className='my-4 flex items-center justify-center'>
          <div className='rounded-md border-4 border-sermons-dark bg-sky-50 p-4 text-center'>
            <div>
              You must{' '}
              <Link
                className='font-bold text-sermons-dark hover:underline'
                href='/user/signup'
              >
                sign up
              </Link>{' '}
              to view the full content.
            </div>
            <div>
              If you already have a subscription, please{' '}
              <Link
                className='font-bold text-sermons-dark hover:underline'
                href='/user/login'
                onClick={() =>
                  localStorage.setItem(
                    'referrer',
                    `${url.pathname}${url.search}`,
                  )
                }
              >
                login
              </Link>
              .
            </div>
          </div>
        </section>
      )}
    </>
  );
}
