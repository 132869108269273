'use client';

import { BIBLE_TRANSLATION, isError, verseReducer } from '@/lib/helpers';
import { PericopeWithVerses } from '@/types/sermons';
import { BsPlusCircleFill } from 'react-icons/bs';
import Accordion from '../molecules/accordion';
import { useAppState } from '../providers/state-provider';

export default function CollapsiblePericope({
  pericope,
  open,
}: {
  pericope?: PericopeWithVerses;
  open?: boolean;
}) {
  const {
    state: { user },
  } = useAppState();
  const translation =
    user && !isError(user)
      ? (user.translation?.toLowerCase() as BIBLE_TRANSLATION) || 'niv'
      : 'niv';
  const paragraphs: JSX.Element[][] | undefined = pericope?.verses.reduce(
    verseReducer(translation),
    [] as JSX.Element[][],
  );

  return (
    <>
      {pericope && (
        <section>
          <Accordion
            header={
              <span className='font-bold'>{`${pericope.fullReference} · ${pericope.heading}`}</span>
            }
            isOpen={open}
            icon={BsPlusCircleFill}
            iconRotation={45}
            isRounded={false}
          >
            <div className='sermon-content'>
              {paragraphs?.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
            </div>
          </Accordion>
        </section>
      )}
    </>
  );
}
