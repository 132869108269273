import { BroadcastEvent, Serializable } from '@/types/sermons';
import { useCallback, useEffect, useRef } from 'react';

export default function useBroadcastChannel<T extends Serializable>(
  channelName: string,
  topic: string,
  onMessage: (data?: T) => void,
) {
  const channelRef = useRef<BroadcastChannel | null>(null);

  useEffect(() => {
    const channel = new BroadcastChannel(channelName);
    channelRef.current = channel;

    channel.onmessage = (event: MessageEvent) => {
      const { topic: messageTopic, data } = event.data as BroadcastEvent<T>;
      if (messageTopic === topic) {
        onMessage(data);
      }
    };

    return () => {
      channel.close();
      channelRef.current = null;
    };
  }, [channelName]); // Only depend on channelName here

  const sendMessage = useCallback(
    (data?: T) => {
      try {
        channelRef.current?.postMessage({ topic, data });
      } catch (error) {
        console.error('Failed to send message:', error);
      }
    },
    [topic], // Dependency on topic for the sendMessage function
  );

  useEffect(() => {
    const currentChannel = channelRef.current;
    if (currentChannel) {
      currentChannel.onmessage = (event: MessageEvent) => {
        const { topic: messageTopic, data } = event.data as BroadcastEvent<T>;
        if (messageTopic === topic) {
          onMessage(data);
        }
      };
    }
  }, [onMessage, topic]); // Handle updates to onMessage and topic without reinitializing the channel

  return sendMessage;
}
