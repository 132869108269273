'use client';

import { Content } from '@/types/sermons';
import classNames from 'classnames';
import { useAppState } from '../providers/state-provider';
import Tools from '../sermons/tools';

export default function SermonTools({ sermon }: { sermon: Content }) {
  const {
    state: { sermonPrepTab },
  } = useAppState();

  return (
    sermon.studyTools && (
      <section
        className={classNames({
          hidden: sermonPrepTab !== 'tools',
        })}
      >
        <Tools studyTools={sermon.studyTools!} sermon={sermon} />
      </section>
    )
  );
}
