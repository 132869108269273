'use client';

import '@/app/(dynamic)/user/signup/loading.css';
import Dialog from '@/components/molecules/dialog';
import { Content, PericopeSuggestion } from '@/types/sermons';
import ContentEditor from './content-editor';

export default function ContentEditDialog({
  sermon,
  isOpen,
  setOpen,
  setSuccess,
  setError,
  isCreate,
  onSave,
  contentType,
  scripture,
  studyToolsId,
}: {
  sermon?: Partial<Content>;
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  setSuccess: (success: string) => void;
  setError: (error: string) => void;
  isCreate?: boolean;
  onSave?: (sermon: Content) => void;
  contentType?: string;
  scripture?: PericopeSuggestion;
  studyToolsId?: number;
}) {
  return (
    <Dialog className='rounded border border-neutral-300' isOpen={isOpen}>
      <ContentEditor
        sermon={sermon}
        setSuccess={setSuccess}
        setError={setError}
        isCreate={isCreate}
        onSave={(content) => {
          if (onSave) {
            onSave(content);
          }
          setOpen(false);
        }}
        onCancel={() => {
          setOpen(false);
        }}
        contentType={contentType}
        scripture={scripture}
        studyToolsId={studyToolsId}
      />
    </Dialog>
  );
}
