'use client';

import Loading from '@/app/(dynamic)/loading';
import { fetchIllustrations } from '@/lib/helpers';
import { Content, ScriptureSearchResults } from '@/types/sermons';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useAppState } from '../providers/state-provider';
import Pagination from '../search/pagination';
import SearchResult from '../search/search-result';

const perPage = 50;

export default function SermonIllustrations({
  sermon,
  illustrationsResults: serverIllustrations,
}: {
  sermon: Content;
  illustrationsResults?: ScriptureSearchResults;
}) {
  const {
    state: { searchParams, sermonPrepTab },
  } = useAppState();

  const pericopeCode = searchParams.get('pericopeCode');

  const pericope = sermon.pericopes?.filter(
    (p) => !pericopeCode || p.number === pericopeCode,
  )?.[0];

  const isDefaultPericope =
    !pericopeCode || sermon.pericopes?.[0].number === pericopeCode;

  const pageParam = searchParams.get('illustrationsPage');
  const page =
    pageParam === null || isNaN(+pageParam) || +pageParam < 1 ? 1 : +pageParam;

  const [illustrationsResults, setIllustrationsResults] =
    useState(serverIllustrations);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (pericope) {
      if (page === 1 && isDefaultPericope) {
        setIllustrationsResults(serverIllustrations);
      } else {
        setLoading(true);
        (async () => {
          const newIllustrationsResults = await fetchIllustrations(
            pericope.number,
            page,
          );
          setIllustrationsResults(newIllustrationsResults);
          setLoading(false);
        })().catch(console.error);
      }
    }
  }, [page]);

  return (
    [1, 8, 10, 15, 16, 17, 19, 24, 25, 28, 29, 35].includes(
      +sermon.contentType,
    ) &&
    pericope && (
      <section
        className={classNames('border border-neutral-300 p-4', {
          hidden: sermonPrepTab !== 'illustrations',
        })}
      >
        {loading && <Loading />}
        {!loading && (
          <>
            <div className='mb-4 flex items-center justify-between'>
              <div className='text-sm text-neutral-500'>
                Showing{' '}
                {(illustrationsResults?.results?.length || 0) > 0 && (
                  <>
                    <span className='font-bold'>
                      {(page - 1) * perPage + 1}
                    </span>{' '}
                    to{' '}
                    <span className='font-bold'>
                      {(illustrationsResults?.total || 0) < page * perPage
                        ? illustrationsResults?.total || 0
                        : page * perPage}
                    </span>{' '}
                    of
                  </>
                )}
                <span className='font-bold'>
                  {illustrationsResults?.total || 0}
                </span>{' '}
                results
              </div>
              <Pagination
                page={page}
                lastPage={illustrationsResults?.lastPage || 1}
                pageParam='illustrationsPage'
              />
            </div>
            {illustrationsResults?.results?.map((result, index) => (
              <React.Fragment key={result.id}>
                {index === 0 && <hr className='my-4' />}
                <SearchResult
                  result={result}
                  index={index + (page - 1) * perPage + 1}
                  allIds={illustrationsResults.allIds}
                  type='Illustrations · Quotes · Humor'
                  hideScripture
                />
                <div className='clear-both'></div>
                <hr className='my-4' />
              </React.Fragment>
            ))}
            {(illustrationsResults?.results?.length || 0) > 0 && (
              <div className='mb-4 flex items-center justify-between'>
                <div className='text-sm text-neutral-500'>
                  Showing{' '}
                  <span className='font-bold'>{(page - 1) * perPage + 1}</span>{' '}
                  to{' '}
                  <span className='font-bold'>
                    {(illustrationsResults?.total || 0) < page * perPage
                      ? illustrationsResults?.total || 0
                      : page * perPage}
                  </span>{' '}
                  of{' '}
                  <span className='font-bold'>
                    {illustrationsResults?.total || 0}
                  </span>{' '}
                  results
                </div>
                <Pagination
                  page={page}
                  lastPage={illustrationsResults?.lastPage || 1}
                  pageParam='illustrationsPage'
                />
              </div>
            )}
          </>
        )}
      </section>
    )
  );
}
